import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: City;
  cityId: Scalars['String']['output'];
  establishment: Establishment;
  establishmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  line: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type AddressCreateInput = {
  cityId: Scalars['String']['input'];
  establishmentId: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  line: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type AddressOrderInput = {
  line?: OrderDirection;
};

export type AddressUpdateInput = {
  cityId: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  line: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type AddressWhereInput = {
  establishmentId: Scalars['String']['input'];
};

export type ApplicantPropertiesRegisterInput = {
  departmentId: Scalars['String']['input'];
  referredByCode?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicantRegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasAcceptedSalesTerms?: InputMaybe<Scalars['Boolean']['input']>;
  hasAcceptedUseTerms?: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  properties: ApplicantPropertiesRegisterInput;
  utm?: InputMaybe<UtmInput>;
};

export type ApplicantSession = {
  __typename?: 'ApplicantSession';
  address?: Maybe<Scalars['String']['output']>;
  availabilityStatus: SessionAvailabilityStatus;
  capacity: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  registrationNumber: Scalars['Float']['output'];
  remainingPlaces: Scalars['Float']['output'];
  remoteLink?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTimeISO']['output'];
  status: SessionStatus;
  type: SessionType;
};

export enum ApplicantStatus {
  Accepted = 'ACCEPTED',
  Deleted = 'DELETED',
  Incomplete = 'INCOMPLETE',
  Recruited = 'RECRUITED',
  Recruitment = 'RECRUITMENT',
  Registered = 'REGISTERED',
  Trained = 'TRAINED',
  Training = 'TRAINING'
}

export type City = {
  __typename?: 'City';
  department: Department;
  departmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CityCreateInput = {
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum CityOrderField {
  Name = 'NAME'
}

export type CityOrderInput = {
  direction?: OrderDirection;
  field: CityOrderField;
};

export type CityUpdateInput = {
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CityWhereInput = {
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ClassRoom = {
  __typename?: 'ClassRoom';
  establishment: Establishment;
  establishmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ClassRoomCreateInput = {
  establishmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum ClassRoomOrderField {
  LevelType = 'LEVEL_TYPE',
  Name = 'NAME'
}

export type ClassRoomOrderInput = {
  direction?: OrderDirection;
  field: ClassRoomOrderField;
};

export type ClassRoomUpdateInput = {
  name: Scalars['String']['input'];
};

export type ClassRoomWhereInput = {
  establishmentId: Scalars['String']['input'];
};

export type Comment = {
  __typename?: 'Comment';
  author: User;
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: CommentType;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type CommentCreateInput = {
  text: Scalars['String']['input'];
  type: CommentType;
  userId: Scalars['String']['input'];
};

export enum CommentType {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export type CommentUpdateInput = {
  text: Scalars['String']['input'];
  type: CommentType;
};

export type CurrentApplicantPropertiesUpdateInput = {
  IBAN?: InputMaybe<Scalars['String']['input']>;
  SIRET?: InputMaybe<Scalars['String']['input']>;
  customStudyEstablishment?: InputMaybe<Scalars['String']['input']>;
  customStudySubject?: InputMaybe<Scalars['String']['input']>;
  diplomaHonour?: InputMaybe<TutorDiplomaHonour>;
  diplomaSpeciality?: InputMaybe<TutorDiplomaSpeciality>;
  diplomaTechnologicalSpeciality?: InputMaybe<TechnologicalSpeciality>;
  diplomaType?: InputMaybe<DiplomaType>;
  diplomaYear?: InputMaybe<Scalars['Float']['input']>;
  firstDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  hasForeignDiploma?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomStudyEstablishment?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomStudySubject?: InputMaybe<Scalars['Boolean']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  otherSourcing?: InputMaybe<Scalars['String']['input']>;
  secondDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  sourcingChannel?: InputMaybe<SourcingChannel>;
  studyEstablishment?: InputMaybe<Scalars['String']['input']>;
  studyLevel?: InputMaybe<TutorStudyLevel>;
  studySubject?: InputMaybe<Scalars['String']['input']>;
  thirdDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  willProvideSIRETLater?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CurrentApplicantSession = {
  __typename?: 'CurrentApplicantSession';
  address?: Maybe<Scalars['String']['output']>;
  capacity: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  remoteLink?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTimeISO']['output'];
  status: SessionStatus;
  type: SessionType;
};

export type CurrentApplicantUpdateInput = {
  properties?: InputMaybe<CurrentApplicantPropertiesUpdateInput>;
};

export type CurrentParentProperties = {
  __typename?: 'CurrentParentProperties';
  childrenNumber: Scalars['Float']['output'];
  currentChildRegistration?: Maybe<ParentChildProfile>;
  hasRegisteredChild: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  registeredChildrenNumber: Scalars['Float']['output'];
};

export type CurrentParentUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type CurrentStaffProperties = {
  __typename?: 'CurrentStaffProperties';
  id: Scalars['String']['output'];
  isRemoteCoordinator: Scalars['Boolean']['output'];
};

export type CurrentTutorAvailability = {
  __typename?: 'CurrentTutorAvailability';
  hasTutorStopped: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isUnknown: Scalars['Boolean']['output'];
  nextAvailability?: Maybe<CurrentTutorAvailability>;
  startDate: Scalars['DateTimeISO']['output'];
  stopReason?: Maybe<Scalars['String']['output']>;
  tutorPropertiesId: Scalars['String']['output'];
  weekAvailability?: Maybe<Array<CurrentTutorWeekdayAvailability>>;
};

export type CurrentTutorProperties = {
  __typename?: 'CurrentTutorProperties';
  IBAN?: Maybe<Scalars['String']['output']>;
  SIRET?: Maybe<Scalars['String']['output']>;
  applicantStatus: ApplicantStatus;
  availability?: Maybe<CurrentTutorAvailability>;
  canSeePermanentMissions: Scalars['Boolean']['output'];
  canSeeReplacementMissions: Scalars['Boolean']['output'];
  contractUrl?: Maybe<Scalars['String']['output']>;
  criminalRecord?: Maybe<File>;
  customStudyEstablishment?: Maybe<Scalars['String']['output']>;
  customStudySubject?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<File>;
  departments?: Maybe<Array<Department>>;
  diplomaHonour?: Maybe<TutorDiplomaHonour>;
  diplomaNewSpecialities: Array<DiplomaNewSpeciality>;
  diplomaSpeciality?: Maybe<TutorDiplomaSpeciality>;
  diplomaTechnologicalSpeciality?: Maybe<TechnologicalSpeciality>;
  diplomaType?: Maybe<DiplomaType>;
  diplomaYear?: Maybe<Scalars['Float']['output']>;
  favoriteCities: Array<City>;
  firstDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  hasCompletedRegistration: Scalars['Boolean']['output'];
  hasForeignDiploma: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  identityDocument?: Maybe<File>;
  incompleteFields: Array<TutorMandatoryFields>;
  isContractSigned: Scalars['Boolean']['output'];
  isCustomStudyEstablishment: Scalars['Boolean']['output'];
  isCustomStudySubject: Scalars['Boolean']['output'];
  isLastChance: Scalars['Boolean']['output'];
  isRejected: Scalars['Boolean']['output'];
  motivation?: Maybe<Scalars['String']['output']>;
  otherFiles: Array<File>;
  otherSourcing?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referredByCode?: Maybe<Scalars['String']['output']>;
  registrationStep: TutorRegistrationStep;
  secondDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  sourcingChannel?: Maybe<SourcingChannel>;
  studyEstablishment?: Maybe<Scalars['String']['output']>;
  studyLevel?: Maybe<TutorStudyLevel>;
  studySubject?: Maybe<Scalars['String']['output']>;
  thirdDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  willProvideSIRETLater: Scalars['Boolean']['output'];
};

export type CurrentTutorPropertiesUpdateInput = {
  IBAN?: InputMaybe<Scalars['String']['input']>;
  SIRET?: InputMaybe<Scalars['String']['input']>;
  availability?: InputMaybe<TutorAvailabilityUpdateInput>;
  canSeePermanentMissions?: InputMaybe<Scalars['Boolean']['input']>;
  canSeeReplacementMissions?: InputMaybe<Scalars['Boolean']['input']>;
  customStudyEstablishment?: InputMaybe<Scalars['String']['input']>;
  customStudySubject?: InputMaybe<Scalars['String']['input']>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  diplomaHonour?: InputMaybe<TutorDiplomaHonour>;
  diplomaSpeciality?: InputMaybe<TutorDiplomaSpeciality>;
  diplomaTechnologicalSpeciality?: InputMaybe<TechnologicalSpeciality>;
  diplomaType?: InputMaybe<DiplomaType>;
  diplomaYear?: InputMaybe<Scalars['Float']['input']>;
  favoriteCitiesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  firstDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  hasForeignDiploma?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomStudyEstablishment?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomStudySubject?: InputMaybe<Scalars['Boolean']['input']>;
  secondDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  studyEstablishment?: InputMaybe<Scalars['String']['input']>;
  studyLevel?: InputMaybe<TutorStudyLevel>;
  studySubject?: InputMaybe<Scalars['String']['input']>;
  thirdDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
};

export type CurrentTutorUpdateInput = {
  properties?: InputMaybe<CurrentTutorPropertiesUpdateInput>;
};

export type CurrentTutorWeekdayAvailability = {
  __typename?: 'CurrentTutorWeekdayAvailability';
  id: Scalars['String']['output'];
  isUnavailable: Scalars['Boolean']['output'];
  startTime?: Maybe<Scalars['DateTimeISO']['output']>;
  weekday: Weekday;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  properties: CurrentUserProperties;
  role: UserRole;
  title?: Maybe<UserTitle>;
};

export type CurrentUserProperties = CurrentParentProperties | CurrentStaffProperties | CurrentTutorProperties;

export type Department = {
  __typename?: 'Department';
  cities: Array<City>;
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  manager: User;
  managerId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  noSessionMailSentAt: Scalars['DateTimeISO']['output'];
  region: Region;
  regionId: Scalars['String']['output'];
  tutorHourlyRate?: Maybe<Scalars['Float']['output']>;
  tutorRemoteHourlyRate?: Maybe<Scalars['Float']['output']>;
};

export type DepartmentCreateInput = {
  code: Scalars['String']['input'];
  isRegionHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegionRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  managerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regionId: Scalars['String']['input'];
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
};

export enum DepartmentOrderField {
  Code = 'CODE'
}

export type DepartmentOrderInput = {
  direction?: OrderDirection;
  field: DepartmentOrderField;
};

export type DepartmentUpdateInput = {
  code: Scalars['String']['input'];
  isRegionHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegionRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  managerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regionId: Scalars['String']['input'];
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
};

export type DepartmentWhereInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum DiplomaNewSpeciality {
  Arts = 'ARTS',
  AutreSpecialite = 'AUTRE_SPECIALITE',
  BiologieEcologie = 'BIOLOGIE_ECOLOGIE',
  EducationPhysiquePratiquesCultureSportives = 'EDUCATION_PHYSIQUE_PRATIQUES_CULTURE_SPORTIVES',
  HistoireGeographieGeopolitiquesSciencesPolitiques = 'HISTOIRE_GEOGRAPHIE_GEOPOLITIQUES_SCIENCES_POLITIQUES',
  HumanitesLitteraturePhilosophie = 'HUMANITES_LITTERATURE_PHILOSOPHIE',
  LanguesLitteratureAntiquites = 'LANGUES_LITTERATURE_ANTIQUITES',
  LanguesLitteratureCultulre = 'LANGUES_LITTERATURE_CULTULRE',
  Mathematiques = 'MATHEMATIQUES',
  NumeriqueInformatique = 'NUMERIQUE_INFORMATIQUE',
  PhysiqueChimie = 'PHYSIQUE_CHIMIE',
  SciencesEconomiquesSociales = 'SCIENCES_ECONOMIQUES_SOCIALES',
  SciencesIngenieur = 'SCIENCES_INGENIEUR',
  SciencesVieTerre = 'SCIENCES_VIE_TERRE'
}

export enum DiplomaType {
  Generale = 'GENERALE',
  Professionnelle = 'PROFESSIONNELLE',
  Technologique = 'TECHNOLOGIQUE'
}

export type Establishment = {
  __typename?: 'Establishment';
  addresses: Array<Address>;
  capacity: Scalars['Float']['output'];
  city: City;
  cityId: Scalars['String']['output'];
  coordinator: User;
  coordinatorId: Scalars['String']['output'];
  defaultAddress?: Maybe<Address>;
  depositWeekNumber?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isRegistrationOpen: Scalars['Boolean']['output'];
  levels: Array<Level>;
  missions: Array<Mission>;
  name: Scalars['String']['output'];
  plans: Array<Plan>;
  schoolClasses: Array<SchoolClass>;
  studentNumber: Scalars['Float']['output'];
  studentNumberPerStudy: Scalars['Float']['output'];
  studentWithMoreThanTwoTutorRate: Scalars['Float']['output'];
  students: Array<StudentProperties>;
  termsLink?: Maybe<Scalars['String']['output']>;
  timeSlots: Array<TimeSlot>;
  tutorHourlyRate?: Maybe<Scalars['Float']['output']>;
  tutorMatchingMissions: Scalars['Float']['output'];
  tutorNumber: Scalars['Float']['output'];
  tutorRemoteHourlyRate?: Maybe<Scalars['Float']['output']>;
  tutorTotalMissions: Scalars['Float']['output'];
  tutors: Array<TutorProperties>;
};

export type EstablishmentCreateInput = {
  capacity: Scalars['Float']['input'];
  cityId: Scalars['String']['input'];
  coordinatorId: Scalars['String']['input'];
  depositWeekNumber?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isDefaultDepositWeekNumberOverridden: Scalars['Boolean']['input'];
  isDepartmentHourlyRateOverridden: Scalars['Boolean']['input'];
  isDepartmentRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  termsLink?: InputMaybe<Scalars['String']['input']>;
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
};

export enum EstablishmentOrderField {
  Name = 'NAME'
}

export type EstablishmentOrderInput = {
  direction?: OrderDirection;
  field: EstablishmentOrderField;
};

export type EstablishmentUpdateInput = {
  capacity: Scalars['Float']['input'];
  cityId: Scalars['String']['input'];
  coordinatorId: Scalars['String']['input'];
  depositWeekNumber?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isDefaultDepositWeekNumberOverridden: Scalars['Boolean']['input'];
  isDepartmentHourlyRateOverridden: Scalars['Boolean']['input'];
  isDepartmentRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  termsLink?: InputMaybe<Scalars['String']['input']>;
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
};

export type EstablishmentWhereInput = {
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnlyActive?: Scalars['Boolean']['input'];
};

export enum FeedbackStatus {
  Absent = 'ABSENT',
  Failed = 'FAILED',
  NotWritten = 'NOT_WRITTEN',
  Saved = 'SAVED',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export type File = {
  __typename?: 'File';
  contentType: Scalars['String']['output'];
  encoding: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalName: Scalars['String']['output'];
  otherFilesTutorProperties?: Maybe<TutorProperties>;
  otherFilesTutorPropertiesId?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type FullScope = {
  __typename?: 'FullScope';
  cities: Array<City>;
  departments: Array<Department>;
  establishments: Array<Establishment>;
  levels: Array<Level>;
  regions: Array<Region>;
};

export type Internship = {
  __typename?: 'Internship';
  endDate: Scalars['DateTimeISO']['output'];
  establishment: Establishment;
  establishmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTimeISO']['output'];
};

export type InternshipCreateInput = {
  endDate: Scalars['DateTimeISO']['input'];
  establishmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['DateTimeISO']['input'];
};

export enum InternshipOrderField {
  StartDate = 'START_DATE'
}

export type InternshipOrderInput = {
  direction?: OrderDirection;
  field: InternshipOrderField;
};

export type InternshipUpdateInput = {
  endDate: Scalars['DateTimeISO']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['DateTimeISO']['input'];
};

export type InternshipWhereInput = {
  establishmentId: Scalars['String']['input'];
};

export type Level = {
  __typename?: 'Level';
  coordinator?: Maybe<User>;
  coordinatorId?: Maybe<Scalars['String']['output']>;
  establishment: Establishment;
  establishmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRegistrationOpen: Scalars['Boolean']['output'];
  isStandardRemoteEnabled: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  plans: Array<Plan>;
  timeSlots: Array<TimeSlot>;
  tutorHourlyRate?: Maybe<Scalars['Float']['output']>;
  tutorRemoteHourlyRate?: Maybe<Scalars['Float']['output']>;
  type: LevelType;
};

export type LevelCreateInput = {
  establishmentId: Scalars['String']['input'];
  isEstablishmentHourlyRateOverridden: Scalars['Boolean']['input'];
  isEstablishmentRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  isStandardRemoteEnabled: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  type: LevelType;
};

export enum LevelOrderField {
  Type = 'TYPE'
}

export type LevelOrderInput = {
  direction?: OrderDirection;
  field: LevelOrderField;
};

export enum LevelType {
  Ce1 = 'CE1',
  Ce2 = 'CE2',
  Cinquieme = 'CINQUIEME',
  Cm1 = 'CM1',
  Cm2 = 'CM2',
  Cp = 'CP',
  Other = 'OTHER',
  Premiere = 'PREMIERE',
  Quatrieme = 'QUATRIEME',
  Seconde = 'SECONDE',
  Sixieme = 'SIXIEME',
  Terminale = 'TERMINALE',
  Troisieme = 'TROISIEME'
}

export type LevelUpdateInput = {
  isEstablishmentHourlyRateOverridden: Scalars['Boolean']['input'];
  isEstablishmentRemoteHourlyRateOverridden: Scalars['Boolean']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  isStandardRemoteEnabled: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tutorHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  tutorRemoteHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  type: LevelType;
};

export type LevelWhereInput = {
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  establishmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isStandardRemoteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
  type?: InputMaybe<LevelType>;
  types?: InputMaybe<Array<LevelType>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  user: CurrentUser;
};

export type Mission = {
  __typename?: 'Mission';
  code: Scalars['Float']['output'];
  color: MissionColor;
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  establishment?: Maybe<Establishment>;
  forcedLevelTypes: Array<LevelType>;
  forcedLevels: Array<Level>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  isStandardRemote: Scalars['Boolean']['output'];
  levelTypes: Array<LevelType>;
  levels: Array<Level>;
  replacedStudies: Array<Study>;
  startDate: Scalars['DateTimeISO']['output'];
  studentsLevelTypes: Array<LevelType>;
  studentsLevels: Array<Level>;
  studies: Array<Study>;
  studySchemas: Array<StudySchema>;
  tutor?: Maybe<User>;
  tutorMissionResponse: TutorMissionResponse;
  type: MissionType;
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy?: Maybe<User>;
  updatedById: Scalars['String']['output'];
};

export enum MissionColor {
  Amber = 'AMBER',
  Cyan = 'CYAN',
  Emerald = 'EMERALD',
  Lime = 'LIME',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED'
}

export enum MissionOrderField {
  CityName = 'CITY_NAME',
  CreatedAt = 'CREATED_AT',
  DepartmentName = 'DEPARTMENT_NAME',
  EstablishmentName = 'ESTABLISHMENT_NAME',
  RegionName = 'REGION_NAME',
  StartDate = 'START_DATE',
  TimeSlotEndTime = 'TIME_SLOT_END_TIME',
  TimeSlotStartTime = 'TIME_SLOT_START_TIME',
  TimeSlotWeekday = 'TIME_SLOT_WEEKDAY',
  TutorFirstName = 'TUTOR_FIRST_NAME',
  TutorLastName = 'TUTOR_LAST_NAME'
}

export type MissionOrderInput = {
  direction?: OrderDirection;
  field: MissionOrderField;
};

export enum MissionType {
  Permanent = 'PERMANENT',
  Replacement = 'REPLACEMENT',
  Temporary = 'TEMPORARY'
}

export type MissionWhereInput = {
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  code?: InputMaybe<Scalars['Float']['input']>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  establishmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isAffected?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isStandardRemote?: InputMaybe<Scalars['Boolean']['input']>;
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  levelTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnlyActive?: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  thematicIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tutorId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MissionType>;
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptMission: Scalars['Boolean']['output'];
  blacklistTutor: Scalars['Boolean']['output'];
  cancelApplicantRejection: Scalars['Boolean']['output'];
  cancelCurrentStudentRegistration: Scalars['Boolean']['output'];
  cancelSession: Session;
  cancelStudies: Scalars['Boolean']['output'];
  cancelStudy: Scalars['Boolean']['output'];
  checkAvailableEmail: Scalars['Boolean']['output'];
  checkAvailablePhone: Scalars['Boolean']['output'];
  checkValidPhone: Scalars['Boolean']['output'];
  checkValidReferralCode: Scalars['Boolean']['output'];
  confirmEmail: LoginResponse;
  createAddress: Address;
  createCity: City;
  createClassRoom: ClassRoom;
  createComment: Comment;
  createDepartment: Department;
  createEstablishment: Establishment;
  createInternship: Internship;
  createLevel: Level;
  createOneOffStudy: Scalars['Boolean']['output'];
  createPeriod: Period;
  createPlan: Plan;
  createRecurrentStudies: Scalars['Boolean']['output'];
  createRecurrentStudy: Scalars['Boolean']['output'];
  createRegion: Region;
  createSchoolClass: SchoolClass;
  createSession: Session;
  createStandardRemotePlan: Plan;
  createStandardRemoteTimeSlot: TimeSlot;
  createThematic: Thematic;
  createTimeSlot: TimeSlot;
  deleteAddress: Scalars['Boolean']['output'];
  deleteApplicant: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  deleteCurrentTutorCV: Scalars['Boolean']['output'];
  deleteCurrentTutorCriminalRecord: Scalars['Boolean']['output'];
  deleteCurrentTutorIdentityDocument: Scalars['Boolean']['output'];
  deleteCurrentTutorOtherFiles: Scalars['Boolean']['output'];
  deleteOneOffStudy: Scalars['Boolean']['output'];
  deletePeriod: Scalars['Boolean']['output'];
  deletePlanningMission: Scalars['Boolean']['output'];
  deleteRecurrentStudy: Scalars['Boolean']['output'];
  deleteSession: Scalars['Boolean']['output'];
  deleteStudentsStudies: Scalars['Boolean']['output'];
  detachPaymentMethod: Scalars['Boolean']['output'];
  endSession: Session;
  endStudyAttendance: Scalars['Boolean']['output'];
  generatePresignedUrl: Scalars['String']['output'];
  handleStudyFromAdmin: Scalars['Boolean']['output'];
  hideMission: Scalars['Boolean']['output'];
  initTutorContract: TutorProperties;
  login: LoginResponse;
  logout: Scalars['Boolean']['output'];
  moveApplicantStatusForward: Scalars['Boolean']['output'];
  publishPlanningMission: Scalars['Boolean']['output'];
  rectifyStudyAttendance: Scalars['Boolean']['output'];
  registerChild: Scalars['Boolean']['output'];
  registerOtherParent: Scalars['Boolean']['output'];
  registerParent: Scalars['Boolean']['output'];
  registerStaff: Scalars['Boolean']['output'];
  registerToSession: Scalars['Boolean']['output'];
  registerTutor: Scalars['Boolean']['output'];
  rejectApplicant: Scalars['Boolean']['output'];
  resendConfirmationMail: Scalars['Boolean']['output'];
  resetCurrentChildRegistrationToPlanStep: Scalars['Boolean']['output'];
  resetPassword: LoginResponse;
  restartStudies: Scalars['Boolean']['output'];
  restartStudy: Scalars['Boolean']['output'];
  revokeRefreshTokensForUser: Scalars['Boolean']['output'];
  sendResetPasswordMail: Scalars['Boolean']['output'];
  sendStudyFeedbacks: Scalars['Boolean']['output'];
  sendTutorContactMail: Scalars['Boolean']['output'];
  sendWelcomeMailNewStackA: Scalars['Boolean']['output'];
  sendWelcomeMailNewStackB: Scalars['Boolean']['output'];
  sendWelcomeMailNewStackC: Scalars['Boolean']['output'];
  setDefaultPaymentMethod: Scalars['Boolean']['output'];
  skipChildRegistrationStep: Scalars['Boolean']['output'];
  startSession: Session;
  startStudy: Scalars['Boolean']['output'];
  switchStudentEstablishment: Scalars['Boolean']['output'];
  syncStudents: StudentSyncResult;
  unHideMission: Scalars['Boolean']['output'];
  unregisterFromSession: Scalars['Boolean']['output'];
  unregisterOtherParent: Scalars['Boolean']['output'];
  updateAddress: Address;
  updateApplicant: User;
  updateChildInformation: ParentChildProfile;
  updateChildProfile: ParentChildProfile;
  updateChildTimeSlots: ParentChildProfile;
  updateCity: City;
  updateClassRoom: ClassRoom;
  updateComment: Comment;
  updateCurrentChildRegistrationBilling: Scalars['Boolean']['output'];
  updateCurrentChildRegistrationInformation: Scalars['Boolean']['output'];
  updateCurrentChildRegistrationPlan: Scalars['Boolean']['output'];
  updateCurrentChildRegistrationProfile: Scalars['Boolean']['output'];
  updateCurrentChildRegistrationTimeSlots: Scalars['Boolean']['output'];
  updateCurrentParent: CurrentUser;
  updateCurrentParentBillingInfo: Scalars['Boolean']['output'];
  updateCurrentTutor: CurrentUser;
  updateCurrentTutorMotivation: User;
  updateCurrentTutorSchoolInformation: User;
  updateDepartment: Department;
  updateEstablishment: Establishment;
  updateInternship: Internship;
  updateLevel: Level;
  updateParameter: Parameter;
  updateParent: Scalars['Boolean']['output'];
  updatePeriod: Period;
  updatePlan: Plan;
  updatePlanningMission: Scalars['Boolean']['output'];
  updateRecurrentStudy: Scalars['Boolean']['output'];
  updateRegion: Region;
  updateSchoolClass: SchoolClass;
  updateSession: Session;
  updateSessionRegistration: SessionRegistration;
  updateStaff: User;
  updateStandardRemotePlan: Plan;
  updateStandardRemoteTimeSlot: TimeSlot;
  updateStudent: User;
  updateStudentAttendance: TutorStudyRegistration;
  updateStudentsStudies: Scalars['Boolean']['output'];
  updateStudy: Scalars['Boolean']['output'];
  updateStudyFeedback: TutorStudyRegistration;
  updateThematic: Thematic;
  updateTimeSlot: TimeSlot;
  updateTutor: User;
  uploadCurrentTutorCV: File;
  uploadCurrentTutorCriminalRecord: File;
  uploadCurrentTutorIdentityDocument: File;
  uploadCurrentTutorOtherFiles: File;
  validateSiret: Scalars['Boolean']['output'];
};


export type MutationAcceptMissionArgs = {
  id: Scalars['String']['input'];
  updatedAt: Scalars['DateTimeISO']['input'];
};


export type MutationBlacklistTutorArgs = {
  id: Scalars['String']['input'];
  isBlacklisted: Scalars['Boolean']['input'];
};


export type MutationCancelApplicantRejectionArgs = {
  id: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelSessionArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelStudiesArgs = {
  studyIds: Array<Scalars['String']['input']>;
};


export type MutationCancelStudyArgs = {
  id: Scalars['String']['input'];
};


export type MutationCheckAvailableEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationCheckAvailablePhoneArgs = {
  phone: Scalars['String']['input'];
};


export type MutationCheckValidPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type MutationCheckValidReferralCodeArgs = {
  referralCode: Scalars['String']['input'];
};


export type MutationConfirmEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateAddressArgs = {
  address: AddressCreateInput;
};


export type MutationCreateCityArgs = {
  city: CityCreateInput;
};


export type MutationCreateClassRoomArgs = {
  classRoom: ClassRoomCreateInput;
};


export type MutationCreateCommentArgs = {
  comment: CommentCreateInput;
};


export type MutationCreateDepartmentArgs = {
  department: DepartmentCreateInput;
};


export type MutationCreateEstablishmentArgs = {
  establishment: EstablishmentCreateInput;
};


export type MutationCreateInternshipArgs = {
  internship: InternshipCreateInput;
};


export type MutationCreateLevelArgs = {
  level: LevelCreateInput;
};


export type MutationCreateOneOffStudyArgs = {
  study: OneOffStudyCreateInput;
};


export type MutationCreatePeriodArgs = {
  period: PeriodCreateInput;
};


export type MutationCreatePlanArgs = {
  plan: PlanCreateInput;
};


export type MutationCreateRecurrentStudiesArgs = {
  studies: StudentStudiesCreateInput;
};


export type MutationCreateRecurrentStudyArgs = {
  study: RecurrentStudyCreateInput;
};


export type MutationCreateRegionArgs = {
  region: RegionCreateInput;
};


export type MutationCreateSchoolClassArgs = {
  schoolClass: SchoolClassCreateInput;
};


export type MutationCreateSessionArgs = {
  session: SessionCreateInput;
};


export type MutationCreateStandardRemotePlanArgs = {
  plan: StandardRemotePlanCreateInput;
};


export type MutationCreateStandardRemoteTimeSlotArgs = {
  timeSlot: StandardRemoteTimeSlotCreateInput;
};


export type MutationCreateThematicArgs = {
  thematic: ThematicCreateInput;
};


export type MutationCreateTimeSlotArgs = {
  timeSlot: TimeSlotCreateInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteApplicantArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCurrentTutorCvArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationDeleteCurrentTutorCriminalRecordArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationDeleteCurrentTutorIdentityDocumentArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationDeleteCurrentTutorOtherFilesArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationDeleteOneOffStudyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePeriodArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePlanningMissionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteRecurrentStudyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSessionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteStudentsStudiesArgs = {
  studentsTimeSlots: Array<PlanningStudentTimeSlotInput>;
};


export type MutationDetachPaymentMethodArgs = {
  id: Scalars['String']['input'];
};


export type MutationEndSessionArgs = {
  id: Scalars['String']['input'];
};


export type MutationEndStudyAttendanceArgs = {
  studyId: Scalars['String']['input'];
};


export type MutationGeneratePresignedUrlArgs = {
  fileId: Scalars['String']['input'];
};


export type MutationHandleStudyFromAdminArgs = {
  studyId: Scalars['String']['input'];
};


export type MutationHideMissionArgs = {
  id: Scalars['String']['input'];
};


export type MutationInitTutorContractArgs = {
  tutorId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationMoveApplicantStatusForwardArgs = {
  id: Scalars['String']['input'];
  status: ApplicantStatus;
};


export type MutationPublishPlanningMissionArgs = {
  id: Scalars['String']['input'];
  isPublished: Scalars['Boolean']['input'];
};


export type MutationRectifyStudyAttendanceArgs = {
  studyId: Scalars['String']['input'];
};


export type MutationRegisterChildArgs = {
  data: StudentInformationInput;
};


export type MutationRegisterOtherParentArgs = {
  data: OtherParentRegisterInput;
};


export type MutationRegisterParentArgs = {
  data: ParentRegisterInput;
};


export type MutationRegisterStaffArgs = {
  data: RegisterStaffInput;
};


export type MutationRegisterToSessionArgs = {
  sessionId: Scalars['String']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterTutorArgs = {
  data: ApplicantRegisterInput;
};


export type MutationRejectApplicantArgs = {
  id: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResendConfirmationMailArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetCurrentChildRegistrationToPlanStepArgs = {
  selectedStudentId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRestartStudiesArgs = {
  studyIds: Array<Scalars['String']['input']>;
};


export type MutationRestartStudyArgs = {
  id: Scalars['String']['input'];
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSendResetPasswordMailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendStudyFeedbacksArgs = {
  studyId: Scalars['String']['input'];
};


export type MutationSendTutorContactMailArgs = {
  message: Scalars['String']['input'];
};


export type MutationSetDefaultPaymentMethodArgs = {
  id: Scalars['String']['input'];
};


export type MutationSkipChildRegistrationStepArgs = {
  step: StudentRegistrationStep;
};


export type MutationStartSessionArgs = {
  id: Scalars['String']['input'];
};


export type MutationStartStudyArgs = {
  studyId: Scalars['String']['input'];
};


export type MutationSwitchStudentEstablishmentArgs = {
  id: Scalars['String']['input'];
  student: StudentEstablishmentSwitchInput;
};


export type MutationUnHideMissionArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnregisterFromSessionArgs = {
  sessionId: Scalars['String']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnregisterOtherParentArgs = {
  childId?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['String']['input'];
};


export type MutationUpdateAddressArgs = {
  address: AddressUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateApplicantArgs = {
  applicant: TutorUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateChildInformationArgs = {
  childId: Scalars['String']['input'];
  data: StudentInformationInput;
};


export type MutationUpdateChildProfileArgs = {
  childId: Scalars['String']['input'];
  data: StudentProfileInput;
};


export type MutationUpdateChildTimeSlotsArgs = {
  childId: Scalars['String']['input'];
  data: StudentTimeSlotsInput;
};


export type MutationUpdateCityArgs = {
  city: CityUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateClassRoomArgs = {
  classRoom: ClassRoomUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateCommentArgs = {
  comment: CommentUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateCurrentChildRegistrationBillingArgs = {
  data: StudentBillingInput;
};


export type MutationUpdateCurrentChildRegistrationInformationArgs = {
  data: StudentInformationInput;
};


export type MutationUpdateCurrentChildRegistrationPlanArgs = {
  data: StudentPlanInput;
};


export type MutationUpdateCurrentChildRegistrationProfileArgs = {
  data: StudentProfileInput;
};


export type MutationUpdateCurrentChildRegistrationTimeSlotsArgs = {
  data: StudentTimeSlotsInput;
};


export type MutationUpdateCurrentParentArgs = {
  data: CurrentParentUpdateInput;
};


export type MutationUpdateCurrentParentBillingInfoArgs = {
  data: StudentBillingInput;
};


export type MutationUpdateCurrentTutorArgs = {
  data: CurrentTutorUpdateInput;
};


export type MutationUpdateCurrentTutorMotivationArgs = {
  data: CurrentApplicantUpdateInput;
};


export type MutationUpdateCurrentTutorSchoolInformationArgs = {
  data: CurrentApplicantUpdateInput;
};


export type MutationUpdateDepartmentArgs = {
  department: DepartmentUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateEstablishmentArgs = {
  establishment: EstablishmentUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateInternshipArgs = {
  id: Scalars['String']['input'];
  internship: InternshipUpdateInput;
};


export type MutationUpdateLevelArgs = {
  id: Scalars['String']['input'];
  level: LevelUpdateInput;
};


export type MutationUpdateParameterArgs = {
  id: Scalars['String']['input'];
  parameter: ParameterUpdateInput;
};


export type MutationUpdateParentArgs = {
  id: Scalars['String']['input'];
  parent: UserUpdateInput;
};


export type MutationUpdatePeriodArgs = {
  id: Scalars['String']['input'];
  period: PeriodUpdateInput;
};


export type MutationUpdatePlanArgs = {
  id: Scalars['String']['input'];
  plan: PlanUpdateInput;
};


export type MutationUpdatePlanningMissionArgs = {
  id: Scalars['String']['input'];
  mission: PlanningMissionUpdateInput;
};


export type MutationUpdateRecurrentStudyArgs = {
  id: Scalars['String']['input'];
  study: RecurrentStudyUpdateInput;
};


export type MutationUpdateRegionArgs = {
  id: Scalars['String']['input'];
  region: RegionUpdateInput;
};


export type MutationUpdateSchoolClassArgs = {
  id: Scalars['String']['input'];
  schoolClass: SchoolClassUpdateInput;
};


export type MutationUpdateSessionArgs = {
  id: Scalars['String']['input'];
  session: SessionUpdateInput;
};


export type MutationUpdateSessionRegistrationArgs = {
  id: Scalars['String']['input'];
  sessionRegistration: SessionRegistrationUpdateInput;
};


export type MutationUpdateStaffArgs = {
  id: Scalars['String']['input'];
  staff: StaffUpdateInput;
};


export type MutationUpdateStandardRemotePlanArgs = {
  id: Scalars['String']['input'];
  plan: StandardRemotePlanUpdateInput;
};


export type MutationUpdateStandardRemoteTimeSlotArgs = {
  id: Scalars['String']['input'];
  timeSlot: StandardRemoteTimeSlotUpdateInput;
};


export type MutationUpdateStudentArgs = {
  id: Scalars['String']['input'];
  student: StudentUpdateInput;
};


export type MutationUpdateStudentAttendanceArgs = {
  isStudentPresent: Scalars['Boolean']['input'];
  studyRegistrationId: Scalars['String']['input'];
};


export type MutationUpdateStudentsStudiesArgs = {
  studies: StudentStudiesUpdateInput;
};


export type MutationUpdateStudyArgs = {
  id: Scalars['String']['input'];
  study: PlanningStudyUpdateInput;
};


export type MutationUpdateStudyFeedbackArgs = {
  feedback: Scalars['String']['input'];
  studyRegistrationId: Scalars['String']['input'];
};


export type MutationUpdateThematicArgs = {
  id: Scalars['String']['input'];
  thematic: ThematicUpdateInput;
};


export type MutationUpdateTimeSlotArgs = {
  id: Scalars['String']['input'];
  timeSlot: TimeSlotUpdateInput;
};


export type MutationUpdateTutorArgs = {
  id: Scalars['String']['input'];
  tutor: TutorUpdateInput;
};


export type MutationUploadCurrentTutorCvArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadCurrentTutorCriminalRecordArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadCurrentTutorIdentityDocumentArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadCurrentTutorOtherFilesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationValidateSiretArgs = {
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type NonPartnerEstablishment = {
  __typename?: 'NonPartnerEstablishment';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OneOffStudyClassRoomCreateInput = {
  name: Scalars['String']['input'];
};

export type OneOffStudyCreateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  classRoom?: InputMaybe<OneOffStudyClassRoomCreateInput>;
  classRoomId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTimeISO']['input'];
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isRemote?: Scalars['Boolean']['input'];
  isStandardRemote?: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  mission?: InputMaybe<OneOffStudyMissionCreateInput>;
  studentIds?: Array<Scalars['String']['input']>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  timeSlotId: Scalars['String']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  type?: StudyType;
};

export type OneOffStudyMissionCreateInput = {
  color: MissionColor;
};

export type OnisepQueryResponse = {
  __typename?: 'OnisepQueryResponse';
  codeUAI?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OtherParent = {
  __typename?: 'OtherParent';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  properties: OtherParentProperties;
};

export type OtherParentProperties = {
  __typename?: 'OtherParentProperties';
  children: Array<ParentChild>;
};

export type OtherParentRegisterInput = {
  childIds: Array<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  title: UserTitle;
};

export type PaginatedAddressResponse = {
  __typename?: 'PaginatedAddressResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Address>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedCityResponse = {
  __typename?: 'PaginatedCityResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<City>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedClassRoomResponse = {
  __typename?: 'PaginatedClassRoomResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<ClassRoom>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedDepartmentResponse = {
  __typename?: 'PaginatedDepartmentResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Department>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedEstablishmentResponse = {
  __typename?: 'PaginatedEstablishmentResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Establishment>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedInternshipResponse = {
  __typename?: 'PaginatedInternshipResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Internship>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedLevelResponse = {
  __typename?: 'PaginatedLevelResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Level>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedMissionResponse = {
  __typename?: 'PaginatedMissionResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Mission>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedParameterResponse = {
  __typename?: 'PaginatedParameterResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Parameter>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedPeriodsResponse = {
  __typename?: 'PaginatedPeriodsResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Period>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedPlanResponse = {
  __typename?: 'PaginatedPlanResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Plan>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedRegionResponse = {
  __typename?: 'PaginatedRegionResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Region>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedSchoolClassResponse = {
  __typename?: 'PaginatedSchoolClassResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<SchoolClass>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedSessionResponse = {
  __typename?: 'PaginatedSessionResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Session>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedThematicResponse = {
  __typename?: 'PaginatedThematicResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<Thematic>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedTimeSlotResponse = {
  __typename?: 'PaginatedTimeSlotResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<TimeSlot>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedTutorMissionResponse = {
  __typename?: 'PaginatedTutorMissionResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<TutorMission>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  hasMore: Scalars['Boolean']['output'];
  items: Array<User>;
  nextOffset: Scalars['Float']['output'];
  pageSize: Scalars['Float']['output'];
  previousOffset: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaginationInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type Parameter = {
  __typename?: 'Parameter';
  id: Scalars['String']['output'];
  type: ParameterType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ParameterType {
  CurrentSchoolYear = 'CURRENT_SCHOOL_YEAR',
  DefaultDepositWeekNumber = 'DEFAULT_DEPOSIT_WEEK_NUMBER',
  IsFeedbackEnabled = 'IS_FEEDBACK_ENABLED',
  IsMissionConfirmationMailEnabled = 'IS_MISSION_CONFIRMATION_MAIL_ENABLED',
  IsParentRegistrationEnabled = 'IS_PARENT_REGISTRATION_ENABLED',
  IsThematicEnabled = 'IS_THEMATIC_ENABLED',
  IsTutorInfosVisible = 'IS_TUTOR_INFOS_VISIBLE',
  IsTutorMissionsVisible = 'IS_TUTOR_MISSIONS_VISIBLE',
  IsTutorRegistrationEnabled = 'IS_TUTOR_REGISTRATION_ENABLED',
  IsUnpaidInvoiceCronEnabled = 'IS_UNPAID_INVOICE_CRON_ENABLED',
  PreRegistrationEndDate = 'PRE_REGISTRATION_END_DATE',
  PreRegistrationStartDate = 'PRE_REGISTRATION_START_DATE',
  ShopifyOrdersAccessToken = 'SHOPIFY_ORDERS_ACCESS_TOKEN',
  ShopifyOrdersStoreDomain = 'SHOPIFY_ORDERS_STORE_DOMAIN',
  StandardRemoteHourlyRate = 'STANDARD_REMOTE_HOURLY_RATE',
  StudentSyncGoogleSpreadsheetId = 'STUDENT_SYNC_GOOGLE_SPREADSHEET_ID'
}

export type ParameterUpdateInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ParentChild = {
  __typename?: 'ParentChild';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  properties: ParentChildProperties;
};

export type ParentChildProfile = {
  __typename?: 'ParentChildProfile';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  properties: ParentChildProfileProperties;
};

export type ParentChildProfileProperties = {
  __typename?: 'ParentChildProfileProperties';
  cityId?: Maybe<Scalars['String']['output']>;
  customNonPartnerEstablishment?: Maybe<Scalars['String']['output']>;
  customSchoolClass?: Maybe<Scalars['String']['output']>;
  departmentId: Scalars['String']['output'];
  depositAmount: Scalars['Float']['output'];
  depositStripeInvoiceId?: Maybe<Scalars['String']['output']>;
  depositWeekNumber: Scalars['Float']['output'];
  diplomaTechnologicalSpeciality?: Maybe<TechnologicalSpeciality>;
  diplomaType?: Maybe<DiplomaType>;
  establishmentId?: Maybe<Scalars['String']['output']>;
  establishmentName?: Maybe<Scalars['String']['output']>;
  firstDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  hasLearningDisorders: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  internship?: Maybe<ParentInternship>;
  isCustomNonPartnerEstablishment?: Maybe<Scalars['Boolean']['output']>;
  isDepositPaid: Scalars['Boolean']['output'];
  isPartnerSchool: Scalars['Boolean']['output'];
  isRemotePlan?: Maybe<Scalars['Boolean']['output']>;
  isSchoolClassUnknown?: Maybe<Scalars['Boolean']['output']>;
  lastStudyRegistration?: Maybe<ParentStudyRegistration>;
  level?: Maybe<ParentLevel>;
  levelId?: Maybe<Scalars['String']['output']>;
  levelType?: Maybe<LevelType>;
  nonPartnerEstablishment?: Maybe<Scalars['String']['output']>;
  parentComment?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<ParentPlan>;
  planId?: Maybe<Scalars['String']['output']>;
  registrationStep: StudentRegistrationStep;
  registrationType: StudentRegistrationType;
  schoolClassId?: Maybe<Scalars['String']['output']>;
  schoolClassName?: Maybe<Scalars['String']['output']>;
  schoolYear: Scalars['String']['output'];
  secondDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  status: StudentStatus;
  subjects?: Maybe<Array<StudentSubject>>;
  thirdDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  timeSlotIds: Array<Scalars['String']['output']>;
};

export type ParentChildProperties = {
  __typename?: 'ParentChildProperties';
  establishmentName?: Maybe<Scalars['String']['output']>;
  internship?: Maybe<ParentInternship>;
  levelType?: Maybe<LevelType>;
  registrationType: StudentRegistrationType;
  schoolClassName?: Maybe<Scalars['String']['output']>;
  schoolYear: Scalars['String']['output'];
  status: StudentStatus;
};

export type ParentCoordinator = {
  __typename?: 'ParentCoordinator';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ParentDepositOption = {
  __typename?: 'ParentDepositOption';
  weekNumber: Scalars['Float']['output'];
};

export type ParentEstablishment = {
  __typename?: 'ParentEstablishment';
  name: Scalars['String']['output'];
};

export type ParentInternship = {
  __typename?: 'ParentInternship';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ParentLevel = {
  __typename?: 'ParentLevel';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: LevelType;
};

export type ParentMission = {
  __typename?: 'ParentMission';
  coordinator?: Maybe<ParentCoordinator>;
  establishment?: Maybe<ParentEstablishment>;
  isStandardRemote: Scalars['Boolean']['output'];
};

export type ParentPlan = {
  __typename?: 'ParentPlan';
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  studiesNumber: Scalars['Float']['output'];
  type: PlanType;
};

export type ParentProperties = {
  __typename?: 'ParentProperties';
  children: Array<StudentProperties>;
  currentChildRegistration?: Maybe<User>;
  currentChildRegistrationId?: Maybe<Scalars['String']['output']>;
  hasTwilioError: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isBillingEnabled: Scalars['Boolean']['output'];
  lastTwilioErrorCode?: Maybe<Scalars['Float']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ParentRegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasAcceptedSalesTerms?: InputMaybe<Scalars['Boolean']['input']>;
  hasAcceptedUseTerms?: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  title: UserTitle;
  utm?: InputMaybe<UtmInput>;
};

export type ParentStudent = {
  __typename?: 'ParentStudent';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type ParentStudy = {
  __typename?: 'ParentStudy';
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  mission: ParentMission;
  timeSlot: ParentTimeSlot;
  timeSlotId: Scalars['String']['output'];
  tutor: ParentTutor;
  tutorPropertiesId?: Maybe<Scalars['String']['output']>;
  type: StudyType;
};

export type ParentStudyRegistration = {
  __typename?: 'ParentStudyRegistration';
  feedback?: Maybe<Scalars['String']['output']>;
  feedbackCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isStudentPresent?: Maybe<Scalars['Boolean']['output']>;
  student: ParentStudent;
  study: ParentStudy;
  studyId: Scalars['String']['output'];
};

export type ParentTimeSlot = {
  __typename?: 'ParentTimeSlot';
  endTime: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  weekday: Weekday;
};

export type ParentTutor = {
  __typename?: 'ParentTutor';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String']['output'];
  exp_month: Scalars['Float']['output'];
  exp_year: Scalars['Float']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card: PaymentCard;
  created: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type Period = {
  __typename?: 'Period';
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  regionIds: Array<Scalars['String']['output']>;
  regions: Array<Region>;
  startDate: Scalars['DateTimeISO']['output'];
};

export type PeriodCreateInput = {
  endDate: Scalars['DateTimeISO']['input'];
  name: Scalars['String']['input'];
  regionIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTimeISO']['input'];
};

export enum PeriodOrderField {
  Name = 'NAME',
  StartDate = 'START_DATE'
}

export type PeriodOrderInput = {
  direction?: OrderDirection;
  field: PeriodOrderField;
};

export type PeriodUpdateInput = {
  endDate: Scalars['DateTimeISO']['input'];
  name: Scalars['String']['input'];
  regionIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTimeISO']['input'];
};

export type PeriodWhereInput = {
  currentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  establishment?: InputMaybe<Scalars['String']['input']>;
};

export type Plan = {
  __typename?: 'Plan';
  establishment?: Maybe<Establishment>;
  establishmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isRegistrationOpen: Scalars['Boolean']['output'];
  isRemote: Scalars['Boolean']['output'];
  levelTypes?: Maybe<Array<LevelType>>;
  levels: Array<Level>;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  studiesNumber: Scalars['Float']['output'];
  type: PlanType;
};

export type PlanCreateInput = {
  establishmentId: Scalars['String']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelIds: Array<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  studiesNumber: Scalars['Float']['input'];
  type?: PlanType;
};

export enum PlanOrderField {
  Name = 'NAME'
}

export type PlanOrderInput = {
  direction?: OrderDirection;
  field: PlanOrderField;
};

export enum PlanType {
  FaceToFace = 'FACE_TO_FACE',
  Remote = 'REMOTE',
  StandardRemote = 'STANDARD_REMOTE'
}

export type PlanUpdateInput = {
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelIds: Array<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  studiesNumber: Scalars['Float']['input'];
  type?: PlanType;
};

export type PlanWhereInput = {
  establishmentId: Scalars['String']['input'];
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
};

export type Planning = {
  __typename?: 'Planning';
  availableStudentsForWeekday: Array<PlanningAvailableStudentsForWeekday>;
  endDate: Scalars['DateTimeISO']['output'];
  establishmentId?: Maybe<Scalars['String']['output']>;
  isStandardRemote: Scalars['Boolean']['output'];
  levelIds?: Maybe<Array<Scalars['String']['output']>>;
  levelTypes?: Maybe<Array<LevelType>>;
  missionsByTutor: Array<PlanningMissionsByTutor>;
  notAffectedMissions: Array<PlanningMission>;
  showOnlyUnassignedStudents: Scalars['Boolean']['output'];
  startDate: Scalars['DateTimeISO']['output'];
  studentNumberPerStudy: Scalars['Float']['output'];
  studentWithMoreThanTwoTutorRate: Scalars['Float']['output'];
  thematicIds?: Maybe<Array<Scalars['String']['output']>>;
  timeSlotsByStudent: Array<PlanningStudentsTimeSlots>;
  timelines: Array<PlanningTimelines>;
  weekdays?: Maybe<Array<Weekday>>;
};


export type PlanningTimeSlotsByStudentArgs = {
  order?: InputMaybe<PlanningByStudentOrder>;
};

export type PlanningAvailableStudent = {
  __typename?: 'PlanningAvailableStudent';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAlreadyPositioned: Scalars['Boolean']['output'];
  isRecommended: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  level?: Maybe<Level>;
  levelType?: Maybe<LevelType>;
  schoolClass?: Maybe<SchoolClass>;
  studentMultipleRegistrations?: Maybe<Array<StudyRegistration>>;
  timeSlots?: Maybe<Array<TimeSlot>>;
};

export type PlanningAvailableStudentForWeekday = {
  __typename?: 'PlanningAvailableStudentForWeekday';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  level?: Maybe<Level>;
  levelType?: Maybe<LevelType>;
  schoolClass?: Maybe<SchoolClass>;
};

export type PlanningAvailableStudentsByTimeSlotForWeekday = {
  __typename?: 'PlanningAvailableStudentsByTimeSlotForWeekday';
  endTime: Scalars['DateTimeISO']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  students: Array<PlanningAvailableStudentForWeekday>;
  timeSlotId: Scalars['String']['output'];
};

export type PlanningAvailableStudentsForWeekday = {
  __typename?: 'PlanningAvailableStudentsForWeekday';
  timeSlots: Array<PlanningAvailableStudentsByTimeSlotForWeekday>;
  weekday: Weekday;
};

export type PlanningAvailableStudentsWhereInput = {
  alreadySelectedStudentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isStandardRemote?: Scalars['Boolean']['input'];
  levelId?: InputMaybe<Scalars['String']['input']>;
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  levelTypes?: InputMaybe<Array<LevelType>>;
  missionId?: InputMaybe<Scalars['String']['input']>;
  schoolClassId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  showOnlyUnassignedStudents?: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  studyId?: InputMaybe<Scalars['String']['input']>;
  timeSlotId?: InputMaybe<Scalars['String']['input']>;
  weekday?: InputMaybe<Weekday>;
};

export enum PlanningByStudentOrder {
  ByLevel = 'BY_LEVEL',
  ByTutor = 'BY_TUTOR'
}

export type PlanningMission = {
  __typename?: 'PlanningMission';
  code: Scalars['Float']['output'];
  color: MissionColor;
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  establishmentId?: Maybe<Scalars['String']['output']>;
  forcedLevelTypes?: Maybe<Array<LevelType>>;
  forcedLevels: Array<Level>;
  id: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  isStandardRemote: Scalars['Boolean']['output'];
  levelTypes: Array<LevelType>;
  levels: Array<Level>;
  nextMissionStartDate?: Maybe<Scalars['DateTimeISO']['output']>;
  previousMissionEndDate?: Maybe<Scalars['DateTimeISO']['output']>;
  replacedStudiesIds?: Maybe<Array<Scalars['String']['output']>>;
  startDate: Scalars['DateTimeISO']['output'];
  studentsLevelTypes?: Maybe<Array<LevelType>>;
  studentsLevels: Array<Level>;
  studies: Array<PlanningMissionStudy>;
  studySchemas: Array<PlanningStudySchema>;
  tutor?: Maybe<PlanningTutor>;
  tutorId?: Maybe<Scalars['String']['output']>;
  type: MissionType;
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy?: Maybe<User>;
};

export type PlanningMissionStudy = {
  __typename?: 'PlanningMissionStudy';
  date: Scalars['DateTimeISO']['output'];
  hasConflictedRegistrations: Scalars['Boolean']['output'];
  hasUnregisteredStudents: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  missionId: Scalars['String']['output'];
  registrationNumber: Scalars['Float']['output'];
  replacementMissionId?: Maybe<Scalars['String']['output']>;
  status: StudyStatus;
  thematic?: Maybe<Thematic>;
  thematicId?: Maybe<Scalars['String']['output']>;
  timeSlot: TimeSlot;
  timeSlotId: Scalars['String']['output'];
  type: StudyType;
};

export type PlanningMissionUpdateInput = {
  color?: MissionColor;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTimeISO']['input'];
  levelIds?: Array<Scalars['String']['input']>;
  levelTypes?: Array<LevelType>;
  startDate: Scalars['DateTimeISO']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  updateTutorFrom?: InputMaybe<Scalars['DateTimeISO']['input']>;
  updatedAt: Scalars['DateTimeISO']['input'];
};

export type PlanningMissionWhereInput = {
  code?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isStandardRemote?: Scalars['Boolean']['input'];
  showOnlyActive?: Scalars['Boolean']['input'];
  startDate: Scalars['DateTimeISO']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  type: MissionType;
};

export type PlanningMissionsByTutor = {
  __typename?: 'PlanningMissionsByTutor';
  missions: Array<PlanningMission>;
  tutor: PlanningTutor;
};

export type PlanningStudent = {
  __typename?: 'PlanningStudent';
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  hasMoreThanTwoTutors: Scalars['Boolean']['output'];
  hasMultipleRegistrations: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  level?: Maybe<Level>;
  levelType?: Maybe<LevelType>;
  schoolClass?: Maybe<SchoolClass>;
  stopAt?: Maybe<Scalars['DateTimeISO']['output']>;
  thematics: Array<Thematic>;
};

export type PlanningStudentTimeSlotInput = {
  studentId: Scalars['String']['input'];
  timeSlotId: Scalars['String']['input'];
};

export type PlanningStudentsTimeSlots = {
  __typename?: 'PlanningStudentsTimeSlots';
  student: PlanningStudent;
  timeSlots: Array<PlanningTimeSlot>;
};

export type PlanningStudiesByTimeSlot = {
  __typename?: 'PlanningStudiesByTimeSlot';
  date: Scalars['DateTimeISO']['output'];
  endTime: Scalars['DateTimeISO']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  studies: Array<PlanningStudy>;
  timeSlotId: Scalars['String']['output'];
};

export type PlanningStudy = {
  __typename?: 'PlanningStudy';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']['output']>;
  classRoom?: Maybe<ClassRoom>;
  classRoomId?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isHandledFromAdmin: Scalars['Boolean']['output'];
  isRemote: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  mission: PlanningMission;
  missionId: Scalars['String']['output'];
  recurrenceRule?: Maybe<StudyRecurrenceRule>;
  recurrenceWeekday?: Maybe<Weekday>;
  registrations: Array<PlanningStudyRegistration>;
  replacedTutor?: Maybe<PlanningTutor>;
  replacementMission?: Maybe<PlanningMission>;
  replacementMissionId?: Maybe<Scalars['String']['output']>;
  status: StudyStatus;
  studySchema?: Maybe<PlanningStudySchema>;
  thematic?: Maybe<Thematic>;
  thematicId?: Maybe<Scalars['String']['output']>;
  timeSlot: TimeSlot;
  timeSlotId: Scalars['String']['output'];
  tutor?: Maybe<PlanningTutor>;
  tutorId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type PlanningStudyRegistration = {
  __typename?: 'PlanningStudyRegistration';
  feedback?: Maybe<Scalars['String']['output']>;
  feedbackSmsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  feedbackStatus?: Maybe<FeedbackStatus>;
  firstName: Scalars['String']['output'];
  hasMultipleRegistrations: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isAdded?: Maybe<Scalars['Boolean']['output']>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  isStudentPresent?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  level?: Maybe<Level>;
  levelType?: Maybe<LevelType>;
  schoolClass?: Maybe<SchoolClass>;
  studentMultipleRegistrations?: Maybe<Array<StudyRegistration>>;
  studentStatus?: Maybe<StudentStatus>;
  twilioSmsStatus?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type PlanningStudySchema = {
  __typename?: 'PlanningStudySchema';
  addressId?: Maybe<Scalars['String']['output']>;
  classRoomId?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  recurrenceRule: StudyRecurrenceRule;
  recurrenceWeekday: Weekday;
  students?: Maybe<Array<User>>;
  thematic?: Maybe<Thematic>;
  thematicId?: Maybe<Scalars['String']['output']>;
  timeSlot: TimeSlot;
  timeSlotId: Scalars['String']['output'];
};

export type PlanningStudyUpdateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  classRoom?: InputMaybe<StudyClassRoomCreateInput>;
  classRoomId?: InputMaybe<Scalars['String']['input']>;
  isHandledFromAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isRemote?: Scalars['Boolean']['input'];
  isReplaced?: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  replacementMission?: InputMaybe<ReplacementMissionInput>;
  studentIds?: Array<Scalars['String']['input']>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  timeSlotId: Scalars['String']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['DateTimeISO']['input'];
};

export type PlanningStudyWhereInput = {
  date: Scalars['DateTimeISO']['input'];
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isStandardRemote?: Scalars['Boolean']['input'];
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PlanningTimeSlot = {
  __typename?: 'PlanningTimeSlot';
  date: Scalars['DateTimeISO']['output'];
  endTime: Scalars['DateTimeISO']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  study?: Maybe<TimeSlotStudy>;
  timeSlotId: Scalars['String']['output'];
};

export type PlanningTimelines = {
  __typename?: 'PlanningTimelines';
  endTime: Scalars['DateTimeISO']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  weekday: Weekday;
};

export type PlanningTutor = {
  __typename?: 'PlanningTutor';
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type PlanningWhereInput = {
  endDate: Scalars['DateTimeISO']['input'];
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isStandardRemote?: Scalars['Boolean']['input'];
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  levelTypes?: InputMaybe<Array<LevelType>>;
  showOnlyUnassignedStudents?: Scalars['Boolean']['input'];
  startDate: Scalars['DateTimeISO']['input'];
  thematicIds?: InputMaybe<Array<Scalars['String']['input']>>;
  weekdays?: InputMaybe<Array<Weekday>>;
};

export enum Profile {
  Literature = 'LITERATURE',
  Science = 'SCIENCE'
}

export type PublicDepartment = {
  __typename?: 'PublicDepartment';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PublicEstablishment = {
  __typename?: 'PublicEstablishment';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PublicLevel = {
  __typename?: 'PublicLevel';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: LevelType;
};

export type PublicSchoolClass = {
  __typename?: 'PublicSchoolClass';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  address: Address;
  addresses: Array<Address>;
  addressesForEstablishment: Array<Address>;
  applicant: User;
  applicantSessions: Array<ApplicantSession>;
  applicants: Array<User>;
  availableStudiesForReplacement: Array<Study>;
  childProfile: ParentChildProfile;
  children: Array<ParentChild>;
  cities: Array<City>;
  citiesWithEstablishments: Array<City>;
  city: City;
  classRoom: ClassRoom;
  classRooms: Array<ClassRoom>;
  comment: Comment;
  coordinators: Array<User>;
  currentApplicantSession?: Maybe<CurrentApplicantSession>;
  currentChildRegistrationDepositOptions: Array<ParentDepositOption>;
  currentChildRegistrationPlans: Array<ParentPlan>;
  currentChildRegistrationTimeSlots: Array<ParentTimeSlot>;
  currentPeriodForEstablishment: Period;
  currentUser?: Maybe<CurrentUser>;
  dayStudiesByTimeSlots: Array<PlanningStudiesByTimeSlot>;
  department: Department;
  departments: Array<Department>;
  departmentsWithEstablishments: Array<PublicDepartment>;
  departmentsWithStudents: Array<PublicDepartment>;
  establishment: Establishment;
  establishmentWorkingDays: Array<Weekday>;
  establishments: Array<Establishment>;
  establishmentsForDepartment: Array<PublicEstablishment>;
  getPhoneCountryCode?: Maybe<Scalars['String']['output']>;
  getReferralTutorName?: Maybe<Scalars['String']['output']>;
  internship: Internship;
  internships: Array<Internship>;
  isDepositOptionAvailable: Scalars['Boolean']['output'];
  isFeedbackEnabled: Scalars['Boolean']['output'];
  isParentRegistrationEnabled: Scalars['Boolean']['output'];
  isPreRegistrationOpen: Scalars['Boolean']['output'];
  isRegisteredParentEmail: Scalars['Boolean']['output'];
  isShopifyOrdersEnabled: Scalars['Boolean']['output'];
  isStudentSyncEnabled: Scalars['Boolean']['output'];
  isThematicEnabled: Scalars['Boolean']['output'];
  isTutorInfosVisible: Scalars['Boolean']['output'];
  isTutorMissionsVisible: Scalars['Boolean']['output'];
  isTutorRegistrationEnabled: Scalars['Boolean']['output'];
  level: Level;
  levels: Array<Level>;
  levelsForEstablishment: Array<Level>;
  managers: Array<User>;
  mission: Mission;
  missions: Array<Mission>;
  nonPartnerEstablishmentsForDepartment: Array<NonPartnerEstablishment>;
  otherChildren: Array<ParentChild>;
  otherParents: Array<OtherParent>;
  paginateAddresses: PaginatedAddressResponse;
  paginateApplicants: PaginatedUserResponse;
  paginateCities: PaginatedCityResponse;
  paginateClassRooms: PaginatedClassRoomResponse;
  paginateDepartments: PaginatedDepartmentResponse;
  paginateEstablishments: PaginatedEstablishmentResponse;
  paginateInternships: PaginatedInternshipResponse;
  paginateLevels: PaginatedLevelResponse;
  paginateMissions: PaginatedMissionResponse;
  paginateParameters: PaginatedParameterResponse;
  paginatePeriods: PaginatedPeriodsResponse;
  paginatePlans: PaginatedPlanResponse;
  paginateRegions: PaginatedRegionResponse;
  paginateSchoolClasses: PaginatedSchoolClassResponse;
  paginateSessions: PaginatedSessionResponse;
  paginateStaff: PaginatedUserResponse;
  paginateStandardRemotePlans: PaginatedPlanResponse;
  paginateStandardRemoteTimeSlots: PaginatedTimeSlotResponse;
  paginateStudents: PaginatedUserResponse;
  paginateThematics: PaginatedThematicResponse;
  paginateTimeSlots: PaginatedTimeSlotResponse;
  paginateTutors: PaginatedUserResponse;
  parameter: Parameter;
  parameters: Array<Parameter>;
  parentChildPlans: Array<ParentPlan>;
  parentChildTimeSlots: Array<ParentTimeSlot>;
  parentLevelsForEstablishment: Array<PublicLevel>;
  paymentMethods: Array<PaymentMethod>;
  period: Period;
  periods: Array<Period>;
  plan: Plan;
  planning: Planning;
  planningAvailableStudents: Array<PlanningAvailableStudent>;
  planningMission: PlanningMission;
  planningMissions: Array<PlanningMission>;
  planningStudy: PlanningStudy;
  plans: Array<Plan>;
  publicDepartments: Array<PublicDepartment>;
  referredTutors?: Maybe<Array<ReferredTutor>>;
  region: Region;
  regions: Array<Region>;
  regionsWithEstablishments: Array<Region>;
  schoolClass: SchoolClass;
  schoolClasses: Array<SchoolClass>;
  schoolClassesForLevel: Array<PublicSchoolClass>;
  schoolYears: Array<Scalars['String']['output']>;
  schoolYearsOpenToRegistration: Array<Scalars['String']['output']>;
  session: Session;
  sessions: Array<Session>;
  staff: User;
  standardRemotePlan: Plan;
  standardRemotePlans: Array<Plan>;
  standardRemoteTimeSlot: TimeSlot;
  standardRemoteTimeSlots: Array<TimeSlot>;
  standardRemoteWorkingDays: Array<Weekday>;
  stripeClientSecret: Scalars['String']['output'];
  stripeDepositClientSecret: Scalars['String']['output'];
  student: User;
  studentsForEstablishment: Array<User>;
  studentsForStandardRemote: Array<User>;
  studyRegistration: TutorStudyRegistration;
  studyRegistrationByFeedbackCode: ParentStudyRegistration;
  studyRegistrations: Array<TutorStudyRegistration>;
  subjects: Array<OnisepQueryResponse>;
  thematic: Thematic;
  thematics: Array<Thematic>;
  timeSlot: TimeSlot;
  timeSlots: Array<TimeSlot>;
  tutor: User;
  tutorAvailableMissions: PaginatedTutorMissionResponse;
  tutorCities: Array<TutorCity>;
  tutorDashboard: TutorDashboard;
  tutorEstablishment: TutorEstablishment;
  tutorMissionEstablishments: Array<TutorEstablishment>;
  tutorMissions: Array<TutorMission>;
  tutorStudy: TutorStudy;
  tutors: Array<User>;
  tutorsByEmails: Array<TutorPayrollInformation>;
  universities: Array<OnisepQueryResponse>;
};


export type QueryAddressArgs = {
  id: Scalars['String']['input'];
};


export type QueryAddressesArgs = {
  order?: InputMaybe<AddressOrderInput>;
  pagination?: InputMaybe<PaginationInput>;
  where: AddressWhereInput;
};


export type QueryAddressesForEstablishmentArgs = {
  establishmentId: Scalars['String']['input'];
};


export type QueryApplicantArgs = {
  id: Scalars['String']['input'];
};


export type QueryApplicantsArgs = {
  order?: InputMaybe<Array<TutorOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<TutorWhereInput>;
};


export type QueryAvailableStudiesForReplacementArgs = {
  studyId: Scalars['String']['input'];
};


export type QueryChildProfileArgs = {
  selectedStudentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCitiesArgs = {
  order?: InputMaybe<Array<CityOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<CityWhereInput>;
};


export type QueryCitiesWithEstablishmentsArgs = {
  order?: InputMaybe<Array<CityOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<CityWhereInput>;
};


export type QueryCityArgs = {
  id: Scalars['String']['input'];
};


export type QueryClassRoomArgs = {
  id: Scalars['String']['input'];
};


export type QueryClassRoomsArgs = {
  order?: InputMaybe<Array<ClassRoomOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: ClassRoomWhereInput;
};


export type QueryCommentArgs = {
  id: Scalars['String']['input'];
};


export type QueryCurrentPeriodForEstablishmentArgs = {
  currentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  establishmentId: Scalars['String']['input'];
};


export type QueryDayStudiesByTimeSlotsArgs = {
  where: PlanningStudyWhereInput;
};


export type QueryDepartmentArgs = {
  id: Scalars['String']['input'];
};


export type QueryDepartmentsArgs = {
  order?: InputMaybe<Array<DepartmentOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<DepartmentWhereInput>;
};


export type QueryDepartmentsWithEstablishmentsArgs = {
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryEstablishmentArgs = {
  id: Scalars['String']['input'];
};


export type QueryEstablishmentWorkingDaysArgs = {
  id: Scalars['String']['input'];
};


export type QueryEstablishmentsArgs = {
  order?: InputMaybe<Array<EstablishmentOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<EstablishmentWhereInput>;
};


export type QueryEstablishmentsForDepartmentArgs = {
  departmentId: Scalars['String']['input'];
};


export type QueryGetPhoneCountryCodeArgs = {
  phone: Scalars['String']['input'];
};


export type QueryGetReferralTutorNameArgs = {
  referralCode: Scalars['String']['input'];
};


export type QueryInternshipArgs = {
  id: Scalars['String']['input'];
};


export type QueryInternshipsArgs = {
  order?: InputMaybe<Array<InternshipOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: InternshipWhereInput;
};


export type QueryIsDepositOptionAvailableArgs = {
  selectedStudentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIsRegisteredParentEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryLevelArgs = {
  id: Scalars['String']['input'];
};


export type QueryLevelsArgs = {
  order?: InputMaybe<Array<LevelOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: LevelWhereInput;
};


export type QueryLevelsForEstablishmentArgs = {
  establishmentId: Scalars['String']['input'];
};


export type QueryMissionArgs = {
  id: Scalars['String']['input'];
};


export type QueryMissionsArgs = {
  order?: InputMaybe<Array<MissionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<MissionWhereInput>;
};


export type QueryNonPartnerEstablishmentsForDepartmentArgs = {
  departmentId: Scalars['String']['input'];
  searchText: Scalars['String']['input'];
};


export type QueryOtherChildrenArgs = {
  selectedStudentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPaginateAddressesArgs = {
  order?: InputMaybe<AddressOrderInput>;
  pagination?: InputMaybe<PaginationInput>;
  where: AddressWhereInput;
};


export type QueryPaginateApplicantsArgs = {
  order?: InputMaybe<Array<TutorOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<TutorWhereInput>;
};


export type QueryPaginateCitiesArgs = {
  order?: InputMaybe<Array<CityOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<CityWhereInput>;
};


export type QueryPaginateClassRoomsArgs = {
  order?: InputMaybe<Array<ClassRoomOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: ClassRoomWhereInput;
};


export type QueryPaginateDepartmentsArgs = {
  order?: InputMaybe<Array<DepartmentOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<DepartmentWhereInput>;
};


export type QueryPaginateEstablishmentsArgs = {
  order?: InputMaybe<Array<EstablishmentOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<EstablishmentWhereInput>;
};


export type QueryPaginateInternshipsArgs = {
  order?: InputMaybe<Array<InternshipOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: InternshipWhereInput;
};


export type QueryPaginateLevelsArgs = {
  order?: InputMaybe<Array<LevelOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: LevelWhereInput;
};


export type QueryPaginateMissionsArgs = {
  order?: InputMaybe<Array<MissionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<MissionWhereInput>;
};


export type QueryPaginateParametersArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryPaginatePeriodsArgs = {
  order?: InputMaybe<Array<PeriodOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<PeriodWhereInput>;
};


export type QueryPaginatePlansArgs = {
  order?: InputMaybe<Array<PlanOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: PlanWhereInput;
};


export type QueryPaginateRegionsArgs = {
  order?: InputMaybe<Array<RegionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QueryPaginateSchoolClassesArgs = {
  order?: InputMaybe<Array<SchoolClassOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: SchoolClassWhereInput;
};


export type QueryPaginateSessionsArgs = {
  order?: InputMaybe<Array<SessionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryPaginateStaffArgs = {
  order?: InputMaybe<Array<StaffOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StaffWhereInput>;
};


export type QueryPaginateStandardRemotePlansArgs = {
  order?: InputMaybe<Array<PlanOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StandardRemotePlanWhereInput>;
};


export type QueryPaginateStandardRemoteTimeSlotsArgs = {
  order?: InputMaybe<Array<TimeSlotOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StandardRemoteTimeSlotWhereInput>;
};


export type QueryPaginateStudentsArgs = {
  order?: InputMaybe<Array<StudentOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StudentWhereInput>;
};


export type QueryPaginateThematicsArgs = {
  order?: InputMaybe<Array<ThematicOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<ThematicWhereInput>;
};


export type QueryPaginateTimeSlotsArgs = {
  order?: InputMaybe<Array<TimeSlotOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: TimeSlotWhereInput;
};


export type QueryPaginateTutorsArgs = {
  order?: InputMaybe<Array<TutorOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<TutorWhereInput>;
};


export type QueryParameterArgs = {
  id: Scalars['String']['input'];
};


export type QueryParametersArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryParentChildPlansArgs = {
  childId: Scalars['String']['input'];
};


export type QueryParentChildTimeSlotsArgs = {
  childId: Scalars['String']['input'];
};


export type QueryParentLevelsForEstablishmentArgs = {
  establishmentId: Scalars['String']['input'];
};


export type QueryPeriodArgs = {
  id: Scalars['String']['input'];
};


export type QueryPeriodsArgs = {
  order?: InputMaybe<PeriodOrderInput>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<PeriodWhereInput>;
};


export type QueryPlanArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlanningArgs = {
  where: PlanningWhereInput;
};


export type QueryPlanningAvailableStudentsArgs = {
  where: PlanningAvailableStudentsWhereInput;
};


export type QueryPlanningMissionArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlanningMissionsArgs = {
  order?: InputMaybe<Array<MissionOrderInput>>;
  where: PlanningMissionWhereInput;
};


export type QueryPlanningStudyArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlansArgs = {
  order?: InputMaybe<Array<PlanOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: PlanWhereInput;
};


export type QueryRegionArgs = {
  id: Scalars['String']['input'];
};


export type QueryRegionsArgs = {
  order?: InputMaybe<Array<RegionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QuerySchoolClassArgs = {
  id: Scalars['String']['input'];
};


export type QuerySchoolClassesArgs = {
  order?: InputMaybe<Array<SchoolClassOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: SchoolClassWhereInput;
};


export type QuerySchoolClassesForLevelArgs = {
  levelId: Scalars['String']['input'];
};


export type QuerySessionArgs = {
  id: Scalars['String']['input'];
};


export type QuerySessionsArgs = {
  order?: InputMaybe<Array<SessionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryStaffArgs = {
  id: Scalars['String']['input'];
};


export type QueryStandardRemotePlanArgs = {
  id: Scalars['String']['input'];
};


export type QueryStandardRemotePlansArgs = {
  order?: InputMaybe<Array<PlanOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StandardRemotePlanWhereInput>;
};


export type QueryStandardRemoteTimeSlotArgs = {
  id: Scalars['String']['input'];
};


export type QueryStandardRemoteTimeSlotsArgs = {
  order?: InputMaybe<Array<TimeSlotOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<StandardRemoteTimeSlotWhereInput>;
};


export type QueryStudentArgs = {
  id: Scalars['String']['input'];
};


export type QueryStudentsForEstablishmentArgs = {
  establishmentId: Scalars['String']['input'];
};


export type QueryStudyRegistrationArgs = {
  id: Scalars['String']['input'];
};


export type QueryStudyRegistrationByFeedbackCodeArgs = {
  feedbackCode: Scalars['String']['input'];
};


export type QueryStudyRegistrationsArgs = {
  studyId: Scalars['String']['input'];
};


export type QuerySubjectsArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryThematicArgs = {
  id: Scalars['String']['input'];
};


export type QueryThematicsArgs = {
  order?: InputMaybe<Array<ThematicOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<ThematicWhereInput>;
};


export type QueryTimeSlotArgs = {
  id: Scalars['String']['input'];
};


export type QueryTimeSlotsArgs = {
  order?: InputMaybe<Array<TimeSlotOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where: TimeSlotWhereInput;
};


export type QueryTutorArgs = {
  id: Scalars['String']['input'];
};


export type QueryTutorAvailableMissionsArgs = {
  order?: InputMaybe<Array<MissionOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<TutorAvailableMissionWhereInput>;
};


export type QueryTutorCitiesArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTutorEstablishmentArgs = {
  establishmentId: Scalars['String']['input'];
  where?: InputMaybe<TutorAvailableMissionWhereInput>;
};


export type QueryTutorStudyArgs = {
  studyId: Scalars['String']['input'];
};


export type QueryTutorsArgs = {
  order?: InputMaybe<Array<TutorOrderInput>>;
  pagination?: InputMaybe<PaginationInput>;
  where?: InputMaybe<TutorWhereInput>;
};


export type QueryTutorsByEmailsArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryUniversitiesArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type RecurrentStudyCreateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  classRoom?: InputMaybe<StudyClassRoomCreateInput>;
  classRoomId?: InputMaybe<Scalars['String']['input']>;
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isNewMission?: InputMaybe<Scalars['Boolean']['input']>;
  isRemote?: Scalars['Boolean']['input'];
  isStandardRemote?: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  mission?: InputMaybe<RecurrentStudyMissionCreateInput>;
  missionId?: InputMaybe<Scalars['String']['input']>;
  recurrenceRule: StudyRecurrenceRule;
  recurrenceWeekday: Weekday;
  studentIds?: Array<Scalars['String']['input']>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  timeSlotId: Scalars['String']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  type?: StudyType;
};

export type RecurrentStudyMissionCreateInput = {
  color: MissionColor;
  endDate: Scalars['DateTimeISO']['input'];
  startDate: Scalars['DateTimeISO']['input'];
};

export type RecurrentStudyUpdateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  classRoom?: InputMaybe<StudyClassRoomCreateInput>;
  classRoomId?: InputMaybe<Scalars['String']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isRemote?: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  studentIds?: Array<Scalars['String']['input']>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  timeSlotId: Scalars['String']['input'];
};

export enum ReferralStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING'
}

export type ReferredTutor = {
  __typename?: 'ReferredTutor';
  createdAt: Scalars['DateTimeISO']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  referralStatus: ReferralStatus;
};

export type Region = {
  __typename?: 'Region';
  departments: Array<Department>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tutorHourlyRate: Scalars['Float']['output'];
  tutorRemoteHourlyRate: Scalars['Float']['output'];
};

export type RegionCreateInput = {
  name: Scalars['String']['input'];
  tutorHourlyRate: Scalars['Float']['input'];
  tutorRemoteHourlyRate: Scalars['Float']['input'];
};

export enum RegionOrderField {
  Name = 'NAME'
}

export type RegionOrderInput = {
  direction?: OrderDirection;
  field: RegionOrderField;
};

export type RegionUpdateInput = {
  name: Scalars['String']['input'];
  tutorHourlyRate: Scalars['Float']['input'];
  tutorRemoteHourlyRate: Scalars['Float']['input'];
};

export type RegionWhereInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterStaffInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasAcceptedSalesTerms?: InputMaybe<Scalars['Boolean']['input']>;
  hasAcceptedUseTerms?: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  properties: StaffPropertiesUpdateInput;
  role: StaffRole;
  scope: ScopeInput;
  utm?: InputMaybe<UtmInput>;
};

export type ReplacementMissionInput = {
  additionalStudiesIds?: Array<Scalars['String']['input']>;
  isPublished?: Scalars['Boolean']['input'];
  tutorId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type SchoolClass = {
  __typename?: 'SchoolClass';
  establishment: Establishment;
  establishmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRegistrationOpen: Scalars['Boolean']['output'];
  level: Level;
  levelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SchoolClassCreateInput = {
  establishmentId: Scalars['String']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum SchoolClassOrderField {
  LevelType = 'LEVEL_TYPE',
  Name = 'NAME'
}

export type SchoolClassOrderInput = {
  direction?: OrderDirection;
  field: SchoolClassOrderField;
};

export type SchoolClassUpdateInput = {
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SchoolClassWhereInput = {
  establishmentId: Scalars['String']['input'];
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
  types?: InputMaybe<Array<LevelType>>;
};

export type Scope = {
  __typename?: 'Scope';
  cities: Array<Scalars['String']['output']>;
  departments: Array<Scalars['String']['output']>;
  establishments: Array<Scalars['String']['output']>;
  levels: Array<Scalars['String']['output']>;
  regions: Array<Scalars['String']['output']>;
};

export type ScopeInput = {
  cities?: Array<Scalars['String']['input']>;
  departments?: Array<Scalars['String']['input']>;
  establishments?: Array<Scalars['String']['input']>;
  levels?: Array<Scalars['String']['input']>;
  regions?: Array<Scalars['String']['input']>;
};

export type Session = {
  __typename?: 'Session';
  address?: Maybe<Scalars['String']['output']>;
  availabilityStatus: SessionAvailabilityStatus;
  capacity: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  departments: Array<Department>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isRemote: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  registrationNumber: Scalars['Float']['output'];
  registrations: Array<SessionRegistration>;
  remainingPlaces: Scalars['Float']['output'];
  remoteLink?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTimeISO']['output'];
  status: SessionStatus;
  type: SessionType;
};

export enum SessionAvailabilityStatus {
  AlmostFull = 'ALMOST_FULL',
  Available = 'AVAILABLE',
  Full = 'FULL'
}

export type SessionCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  capacity: Scalars['Float']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  departmentIds: Array<Scalars['String']['input']>;
  endDate: Scalars['DateTimeISO']['input'];
  isRemote: Scalars['Boolean']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  remoteLink?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTimeISO']['input'];
  type: SessionType;
};

export enum SessionOrderField {
  CreatedAt = 'CREATED_AT',
  StartDate = 'START_DATE'
}

export type SessionOrderInput = {
  direction?: OrderDirection;
  field: SessionOrderField;
};

export type SessionRegistration = {
  __typename?: 'SessionRegistration';
  id: Scalars['String']['output'];
  isTutorPresent?: Maybe<Scalars['Boolean']['output']>;
  isTutorRejected?: Maybe<Scalars['Boolean']['output']>;
  session: Session;
  sessionId: Scalars['String']['output'];
  tutorProperties: TutorProperties;
  tutorPropertiesId: Scalars['String']['output'];
};

export type SessionRegistrationUpdateInput = {
  isTutorPresent?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SessionStatus {
  Canceled = 'CANCELED',
  Done = 'DONE',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export enum SessionType {
  Recruitment = 'RECRUITMENT',
  Training = 'TRAINING'
}

export type SessionUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  capacity: Scalars['Float']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  departmentIds: Array<Scalars['String']['input']>;
  endDate: Scalars['DateTimeISO']['input'];
  isRemote: Scalars['Boolean']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  remoteLink?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTimeISO']['input'];
  type: SessionType;
};

export type SessionWhereInput = {
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isFull?: InputMaybe<Scalars['Boolean']['input']>;
  isRemote?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SessionStatus>>;
  type?: InputMaybe<SessionType>;
};

export enum SourcingChannel {
  Ads = 'ADS',
  Flyer = 'FLYER',
  Organic = 'ORGANIC',
  Other = 'OTHER',
  School = 'SCHOOL',
  Socials = 'SOCIALS'
}

export enum StaffOrderField {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export type StaffOrderInput = {
  direction?: OrderDirection;
  field: StaffOrderField;
};

export type StaffProperties = {
  __typename?: 'StaffProperties';
  id: Scalars['String']['output'];
  isRemoteCoordinator: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type StaffPropertiesUpdateInput = {
  isRemoteCoordinator?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum StaffRole {
  Admin = 'ADMIN',
  Coordinator = 'COORDINATOR',
  Manager = 'MANAGER'
}

export type StaffUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  properties: StaffPropertiesUpdateInput;
  role: UserRole;
  scope: ScopeInput;
  title?: InputMaybe<UserTitle>;
};

export type StaffWhereInput = {
  roles?: InputMaybe<Array<StaffRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type StandardRemotePlanCreateInput = {
  isRegistrationOpen?: Scalars['Boolean']['input'];
  levelTypes: Array<LevelType>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  studiesNumber: Scalars['Float']['input'];
};

export type StandardRemotePlanUpdateInput = {
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelTypes: Array<LevelType>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  studiesNumber: Scalars['Float']['input'];
};

export type StandardRemotePlanWhereInput = {
  levelTypes?: InputMaybe<Array<LevelType>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
};

export type StandardRemoteTimeSlotCreateInput = {
  endTime: Scalars['DateTimeISO']['input'];
  isRegistrationOpen?: Scalars['Boolean']['input'];
  levelTypes: Array<LevelType>;
  startTime: Scalars['DateTimeISO']['input'];
  weekday: Weekday;
};

export type StandardRemoteTimeSlotUpdateInput = {
  endTime: Scalars['DateTimeISO']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelTypes: Array<LevelType>;
  startTime: Scalars['DateTimeISO']['input'];
  weekday: Weekday;
};

export type StandardRemoteTimeSlotWhereInput = {
  levelTypes?: InputMaybe<Array<LevelType>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type StudentBillingInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type StudentEstablishmentSwitchInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  properties: StudentPropertiesEstablishmentSwitchInput;
};

export type StudentInformationInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  properties?: InputMaybe<StudentPropertiesInformationInput>;
};

export enum StudentOrderField {
  CreatedAt = 'CREATED_AT'
}

export type StudentOrderInput = {
  direction?: OrderDirection;
  field: StudentOrderField;
};

export type StudentPlanInput = {
  depositWeekNumber?: InputMaybe<Scalars['Float']['input']>;
  isRemotePlan?: InputMaybe<Scalars['Boolean']['input']>;
  planId: Scalars['String']['input'];
};

export type StudentProfileInput = {
  diplomaTechnologicalSpeciality?: InputMaybe<TechnologicalSpeciality>;
  diplomaType?: InputMaybe<DiplomaType>;
  firstDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  hasLearningDisorders?: InputMaybe<Scalars['Boolean']['input']>;
  parentComment?: InputMaybe<Scalars['String']['input']>;
  secondDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
  subjects?: InputMaybe<StudentSubjectInput>;
  thirdDiplomaNewSpeciality?: InputMaybe<DiplomaNewSpeciality>;
};

export type StudentProperties = {
  __typename?: 'StudentProperties';
  availablePlans: Array<Plan>;
  availableSchoolClasses: Array<SchoolClass>;
  availableThematics: Array<Thematic>;
  availableTimeSlots: Array<TimeSlot>;
  comment?: Maybe<Scalars['String']['output']>;
  customNonPartnerEstablishment?: Maybe<Scalars['String']['output']>;
  customSchoolClass?: Maybe<Scalars['String']['output']>;
  department: Department;
  departmentId: Scalars['String']['output'];
  depositAmount: Scalars['Float']['output'];
  depositStripeInvoiceId?: Maybe<Scalars['String']['output']>;
  depositWeekNumber: Scalars['Float']['output'];
  diplomaTechnologicalSpeciality?: Maybe<TechnologicalSpeciality>;
  diplomaType?: Maybe<DiplomaType>;
  discount: Scalars['Float']['output'];
  establishment?: Maybe<Establishment>;
  establishmentId?: Maybe<Scalars['String']['output']>;
  firstDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  hasLearningDisorders: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  incompleteRegistrationMailSentNumber: Scalars['Float']['output'];
  internship?: Maybe<Internship>;
  internshipId?: Maybe<Scalars['String']['output']>;
  isCustomNonPartnerEstablishment?: Maybe<Scalars['Boolean']['output']>;
  isDepositPaid: Scalars['Boolean']['output'];
  isPartnerSchool: Scalars['Boolean']['output'];
  isRemotePlan?: Maybe<Scalars['Boolean']['output']>;
  isSchoolClassUnknown?: Maybe<Scalars['Boolean']['output']>;
  isStandardRemotePlan?: Maybe<Scalars['Boolean']['output']>;
  lastIncompleteRegistrationMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  level?: Maybe<Level>;
  levelId?: Maybe<Scalars['String']['output']>;
  levelType?: Maybe<LevelType>;
  newRegistrationMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  nonPartnerEstablishment?: Maybe<Scalars['String']['output']>;
  parentComment?: Maybe<Scalars['String']['output']>;
  parents: Array<ParentProperties>;
  plan?: Maybe<Plan>;
  planId?: Maybe<Scalars['String']['output']>;
  preRegistrationReminderMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  registrationStep: StudentRegistrationStep;
  registrationType: StudentRegistrationType;
  schoolClass?: Maybe<SchoolClass>;
  schoolClassId?: Maybe<Scalars['String']['output']>;
  schoolYear: Scalars['String']['output'];
  secondDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  startAt?: Maybe<Scalars['DateTimeISO']['output']>;
  status: StudentStatus;
  stopAt?: Maybe<Scalars['DateTimeISO']['output']>;
  studyRegistrations: Array<StudyRegistration>;
  studySchemas: Array<StudySchema>;
  subjects?: Maybe<Array<StudentSubject>>;
  thematicIds: Array<Scalars['String']['output']>;
  thematics: Array<Thematic>;
  thirdDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  timeSlotIds: Array<Scalars['String']['output']>;
  timeSlots: Array<TimeSlot>;
  user: User;
  userId: Scalars['String']['output'];
  waitingListMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type StudentPropertiesEstablishmentSwitchInput = {
  customNonPartnerEstablishment?: InputMaybe<Scalars['String']['input']>;
  customSchoolClass?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['String']['input'];
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isCustomNonPartnerEstablishment?: InputMaybe<Scalars['Boolean']['input']>;
  isPartnerSchool: Scalars['Boolean']['input'];
  isSchoolClassUnknown?: Scalars['Boolean']['input'];
  levelId?: InputMaybe<Scalars['String']['input']>;
  levelType?: InputMaybe<LevelType>;
  nonPartnerEstablishment?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  schoolClassId?: InputMaybe<Scalars['String']['input']>;
  schoolYear: Scalars['String']['input'];
  thematicIds?: InputMaybe<Array<Scalars['String']['input']>>;
  timeSlotIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StudentPropertiesInformationInput = {
  customNonPartnerEstablishment?: InputMaybe<Scalars['String']['input']>;
  customSchoolClass?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['String']['input'];
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isCustomNonPartnerEstablishment?: InputMaybe<Scalars['Boolean']['input']>;
  isPartnerSchool: Scalars['Boolean']['input'];
  isSchoolClassUnknown?: Scalars['Boolean']['input'];
  levelId?: InputMaybe<Scalars['String']['input']>;
  levelType?: InputMaybe<LevelType>;
  nonPartnerEstablishment?: InputMaybe<Scalars['String']['input']>;
  schoolClassId?: InputMaybe<Scalars['String']['input']>;
  schoolYear: Scalars['String']['input'];
};

export type StudentPropertiesUpdateInput = {
  discount?: Scalars['Float']['input'];
  levelType?: InputMaybe<LevelType>;
  planId?: InputMaybe<Scalars['String']['input']>;
  schoolClassId?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  status?: InputMaybe<StudentStatus>;
  stopAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  thematicIds?: InputMaybe<Array<Scalars['String']['input']>>;
  timeSlotIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum StudentRegistrationStep {
  Billing = 'BILLING',
  Completed = 'COMPLETED',
  Information = 'INFORMATION',
  Plan = 'PLAN',
  Profile = 'PROFILE',
  TimeSlots = 'TIME_SLOTS'
}

export enum StudentRegistrationType {
  Internship = 'INTERNSHIP',
  Study = 'STUDY'
}

export enum StudentStatus {
  Deposit = 'DEPOSIT',
  Incomplete = 'INCOMPLETE',
  Request = 'REQUEST',
  Unregistered = 'UNREGISTERED',
  Validated = 'VALIDATED',
  WaitingList = 'WAITING_LIST'
}

export type StudentStudiesCreateInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  classRoom?: InputMaybe<StudyClassRoomCreateInput>;
  classRoomId?: InputMaybe<Scalars['String']['input']>;
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isNewMission: Scalars['Boolean']['input'];
  isRemote?: Scalars['Boolean']['input'];
  isStandardRemote?: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  mission?: InputMaybe<RecurrentStudyMissionCreateInput>;
  missionId?: InputMaybe<Scalars['String']['input']>;
  recurrenceRule: StudyRecurrenceRule;
  studentsTimeSlots: Array<PlanningStudentTimeSlotInput>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  tutorId?: InputMaybe<Scalars['String']['input']>;
};

export type StudentStudiesUpdateInput = {
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isNewClassRoom?: Scalars['Boolean']['input'];
  isNewMission: Scalars['Boolean']['input'];
  isRemote?: Scalars['Boolean']['input'];
  isStandardRemote?: Scalars['Boolean']['input'];
  mission?: InputMaybe<RecurrentStudyMissionCreateInput>;
  missionId?: InputMaybe<Scalars['String']['input']>;
  recurrenceRule: StudyRecurrenceRule;
  studentsTimeSlots: Array<PlanningStudentTimeSlotInput>;
  thematicId?: InputMaybe<Scalars['String']['input']>;
  tutorId?: InputMaybe<Scalars['String']['input']>;
};

export type StudentSubject = {
  __typename?: 'StudentSubject';
  id: Scalars['String']['output'];
  level: SubjectLevel;
  studentProperties: StudentProperties;
  studentPropertiesId: Scalars['String']['output'];
  subject: Subject;
};

export type StudentSubjectInput = {
  ALLEMAND?: InputMaybe<SubjectLevel>;
  ANGLAIS?: InputMaybe<SubjectLevel>;
  ARTS_APPLIQUES_ET_CULTURES_ARTISTIQUES?: InputMaybe<SubjectLevel>;
  BIOLOGIE_ECOLOGIE?: InputMaybe<SubjectLevel>;
  ECONOMIE_DROIT?: InputMaybe<SubjectLevel>;
  ECONOMIE_GESTION?: InputMaybe<SubjectLevel>;
  EDUCATION_PHYSIQUE_PRATIQUES_ET_CULTURE_SPORTIVES?: InputMaybe<SubjectLevel>;
  ENSEIGNEMENT_MORAL_ET_CIVIQUE?: InputMaybe<SubjectLevel>;
  ENSEIGNEMENT_SCIENTIFIQUE?: InputMaybe<SubjectLevel>;
  ESPAGNOL?: InputMaybe<SubjectLevel>;
  FRANCAIS?: InputMaybe<SubjectLevel>;
  HISTOIRE_ET_GEOGRAPHIE?: InputMaybe<SubjectLevel>;
  HISTOIRE_GEOGRAPHIE_GEOPOLITIQUE_ET_SCIENCES_POLITIQUES?: InputMaybe<SubjectLevel>;
  HUMANITES_LITTERATURE_ET_PHILOSOPHIE?: InputMaybe<SubjectLevel>;
  LANGUES_LITTERATURES_ET_CULTURES_ETRANGERES_ET_REGIONALES?: InputMaybe<SubjectLevel>;
  LITTERATURES_ET_LANGUES_ET_CULTURES_DE_L_ANTIQUITE?: InputMaybe<SubjectLevel>;
  MATHEMATIQUES?: InputMaybe<SubjectLevel>;
  NUMERIQUE_ET_SCIENCES_INFORMATIQUES?: InputMaybe<SubjectLevel>;
  PHILOSOPHIE?: InputMaybe<SubjectLevel>;
  PHYSIQUE_CHIMIE?: InputMaybe<SubjectLevel>;
  PREVENTION_SANTE_ENVIRONNEMENT?: InputMaybe<SubjectLevel>;
  SCIENCES_DE_LA_VIE_ET_DE_LA_TERRE?: InputMaybe<SubjectLevel>;
  SCIENCES_DE_L_INGENIEUR?: InputMaybe<SubjectLevel>;
  SCIENCES_ECONOMIQUES_ET_SOCIALES?: InputMaybe<SubjectLevel>;
  SCIENCES_ET_TECHNOLOGIES?: InputMaybe<SubjectLevel>;
  SCIENCES_NUMERIQUES_ET_TECHNOLOGIE?: InputMaybe<SubjectLevel>;
};

export type StudentSyncResult = {
  __typename?: 'StudentSyncResult';
  syncedParentNumber: Scalars['Float']['output'];
  syncedStudentNumber: Scalars['Float']['output'];
};

export type StudentTimeSlotsInput = {
  timeSlotIds: Array<Scalars['String']['input']>;
};

export type StudentUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<StudentPropertiesUpdateInput>;
  title?: InputMaybe<UserTitle>;
};

export type StudentWhereInput = {
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  establishmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isStandardRemotePlan?: Scalars['Boolean']['input'];
  levels?: InputMaybe<Array<LevelType>>;
  planIds?: InputMaybe<Array<Scalars['String']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  registrationType?: InputMaybe<StudentRegistrationType>;
  schoolYear?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<StudentStatus>>;
  studyDays?: InputMaybe<Array<InputMaybe<Weekday>>>;
};

export type Study = {
  __typename?: 'Study';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']['output']>;
  classRoom?: Maybe<ClassRoom>;
  classRoomId?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTimeISO']['output'];
  establishment?: Maybe<Establishment>;
  establishmentId?: Maybe<Scalars['String']['output']>;
  hasConflictedRegistrations: Scalars['Boolean']['output'];
  hasUnregisteredStudents: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isHandledFromAdmin: Scalars['Boolean']['output'];
  isRemote: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  mission: Mission;
  missionId: Scalars['String']['output'];
  registrationNumber: Scalars['Float']['output'];
  registrations: Array<StudyRegistration>;
  replacementMission?: Maybe<Mission>;
  replacementMissionId?: Maybe<Scalars['String']['output']>;
  status: StudyStatus;
  studySchema?: Maybe<StudySchema>;
  studySchemaId?: Maybe<Scalars['String']['output']>;
  thematic?: Maybe<Thematic>;
  thematicId?: Maybe<Scalars['String']['output']>;
  timeSlot: TimeSlot;
  timeSlotId: Scalars['String']['output'];
  tutorProperties?: Maybe<TutorProperties>;
  tutorPropertiesId?: Maybe<Scalars['String']['output']>;
  type: StudyType;
};

export type StudyClassRoomCreateInput = {
  name: Scalars['String']['input'];
};

export enum StudyRecurrenceRule {
  EveryEvenWeek = 'EVERY_EVEN_WEEK',
  EveryOddWeek = 'EVERY_ODD_WEEK',
  Weekly = 'WEEKLY'
}

export type StudyRegistration = {
  __typename?: 'StudyRegistration';
  absentSmsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  feedbackSmsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  isStudentPresent?: Maybe<Scalars['Boolean']['output']>;
  presentSmsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  studentProperties: StudentProperties;
  studentPropertiesId: Scalars['String']['output'];
  study: Study;
  studyId: Scalars['String']['output'];
};

export type StudySchema = {
  __typename?: 'StudySchema';
  classRoom?: Maybe<ClassRoom>;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  recurrenceRule: StudyRecurrenceRule;
  recurrenceWeekday: Weekday;
  startDate: Scalars['DateTimeISO']['output'];
  studentsProperties: Array<StudentProperties>;
  thematic: Thematic;
  thematicId?: Maybe<Scalars['String']['output']>;
  timeSlot: TimeSlot;
};

export enum StudyStatus {
  AttendanceDone = 'ATTENDANCE_DONE',
  Canceled = 'CANCELED',
  DebriefDone = 'DEBRIEF_DONE',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export enum StudyType {
  Internship = 'INTERNSHIP',
  Study = 'STUDY'
}

export enum Subject {
  Allemand = 'ALLEMAND',
  Anglais = 'ANGLAIS',
  ArtsAppliquesEtCulturesArtistiques = 'ARTS_APPLIQUES_ET_CULTURES_ARTISTIQUES',
  BiologieEcologie = 'BIOLOGIE_ECOLOGIE',
  EconomieDroit = 'ECONOMIE_DROIT',
  EconomieGestion = 'ECONOMIE_GESTION',
  EducationPhysiquePratiquesEtCultureSportives = 'EDUCATION_PHYSIQUE_PRATIQUES_ET_CULTURE_SPORTIVES',
  EnseignementMoralEtCivique = 'ENSEIGNEMENT_MORAL_ET_CIVIQUE',
  EnseignementScientifique = 'ENSEIGNEMENT_SCIENTIFIQUE',
  Espagnol = 'ESPAGNOL',
  Francais = 'FRANCAIS',
  HistoireEtGeographie = 'HISTOIRE_ET_GEOGRAPHIE',
  HistoireGeographieGeopolitiqueEtSciencesPolitiques = 'HISTOIRE_GEOGRAPHIE_GEOPOLITIQUE_ET_SCIENCES_POLITIQUES',
  HumanitesLitteratureEtPhilosophie = 'HUMANITES_LITTERATURE_ET_PHILOSOPHIE',
  LanguesLitteraturesEtCulturesEtrangeresEtRegionales = 'LANGUES_LITTERATURES_ET_CULTURES_ETRANGERES_ET_REGIONALES',
  LitteraturesEtLanguesEtCulturesDeLAntiquite = 'LITTERATURES_ET_LANGUES_ET_CULTURES_DE_L_ANTIQUITE',
  Mathematiques = 'MATHEMATIQUES',
  NumeriqueEtSciencesInformatiques = 'NUMERIQUE_ET_SCIENCES_INFORMATIQUES',
  Philosophie = 'PHILOSOPHIE',
  PhysiqueChimie = 'PHYSIQUE_CHIMIE',
  PreventionSanteEnvironnement = 'PREVENTION_SANTE_ENVIRONNEMENT',
  SciencesDeLaVieEtDeLaTerre = 'SCIENCES_DE_LA_VIE_ET_DE_LA_TERRE',
  SciencesDeLIngenieur = 'SCIENCES_DE_L_INGENIEUR',
  SciencesEconomiquesEtSociales = 'SCIENCES_ECONOMIQUES_ET_SOCIALES',
  SciencesEtTechnologies = 'SCIENCES_ET_TECHNOLOGIES',
  SciencesNumeriquesEtTechnologie = 'SCIENCES_NUMERIQUES_ET_TECHNOLOGIE'
}

export enum SubjectLevel {
  Confortable = 'CONFORTABLE',
  DoNotKnow = 'DO_NOT_KNOW',
  Intermediate = 'INTERMEDIATE',
  InDifficulty = 'IN_DIFFICULTY',
  InSlightDifficulty = 'IN_SLIGHT_DIFFICULTY',
  NotConcerned = 'NOT_CONCERNED',
  VeryConfortable = 'VERY_CONFORTABLE'
}

export enum TechnologicalSpeciality {
  S2Tmd = 'S2TMD',
  St2S = 'ST2S',
  Stav = 'STAV',
  Std2A = 'STD2A',
  Sthr = 'STHR',
  Sti2D = 'STI2D',
  Stl = 'STL',
  Stmg = 'STMG'
}

export type Thematic = {
  __typename?: 'Thematic';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ThematicCreateInput = {
  name: Scalars['String']['input'];
};

export enum ThematicOrderField {
  Name = 'NAME'
}

export type ThematicOrderInput = {
  direction?: OrderDirection;
  field: ThematicOrderField;
};

export type ThematicUpdateInput = {
  name: Scalars['String']['input'];
};

export type ThematicWhereInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime: Scalars['DateTimeISO']['output'];
  establishment?: Maybe<Establishment>;
  establishmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isRegistrationOpen: Scalars['Boolean']['output'];
  isStandardRemote: Scalars['Boolean']['output'];
  levelTypes?: Maybe<Array<LevelType>>;
  levels: Array<Level>;
  startTime: Scalars['DateTimeISO']['output'];
  weekday: Weekday;
};

export type TimeSlotCreateInput = {
  endTime: Scalars['DateTimeISO']['input'];
  establishmentId: Scalars['String']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelIds?: Array<Scalars['String']['input']>;
  startTime: Scalars['DateTimeISO']['input'];
  weekday: Weekday;
};

export enum TimeSlotOrderField {
  EndTime = 'END_TIME',
  StartTime = 'START_TIME',
  Weekday = 'WEEKDAY'
}

export type TimeSlotOrderInput = {
  direction?: OrderDirection;
  field: TimeSlotOrderField;
};

export type TimeSlotStudy = {
  __typename?: 'TimeSlotStudy';
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  mission: TimeSlotStudyMission;
  timeSlot: TimeSlot;
};

export type TimeSlotStudyMission = {
  __typename?: 'TimeSlotStudyMission';
  code: Scalars['Float']['output'];
  color: MissionColor;
  endDate: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  startDate: Scalars['DateTimeISO']['output'];
  tutor?: Maybe<PlanningTutor>;
};

export type TimeSlotUpdateInput = {
  endTime: Scalars['DateTimeISO']['input'];
  isRegistrationOpen: Scalars['Boolean']['input'];
  levelIds: Array<Scalars['String']['input']>;
  startTime: Scalars['DateTimeISO']['input'];
  weekday: Weekday;
};

export type TimeSlotWhereInput = {
  establishmentId?: InputMaybe<Scalars['String']['input']>;
  isStandardRemote?: Scalars['Boolean']['input'];
  levelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  showOnlyOpen?: Scalars['Boolean']['input'];
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  date: Scalars['DateTimeISO']['output'];
  type: TimelineEventType;
};

export enum TimelineEventType {
  AbsentFromRecruitmentSession = 'ABSENT_FROM_RECRUITMENT_SESSION',
  AbsentFromRecruitmentSessionEmailSent = 'ABSENT_FROM_RECRUITMENT_SESSION_EMAIL_SENT',
  AbsentFromTrainingSession = 'ABSENT_FROM_TRAINING_SESSION',
  AcceptedEmailScheduled = 'ACCEPTED_EMAIL_SCHEDULED',
  AcceptedEmailSent = 'ACCEPTED_EMAIL_SENT',
  AcceptedFromRecruitmentSession = 'ACCEPTED_FROM_RECRUITMENT_SESSION',
  AcceptedFromRecruitmentSessionEmailSent = 'ACCEPTED_FROM_RECRUITMENT_SESSION_EMAIL_SENT',
  AcceptedFromTrainingSession = 'ACCEPTED_FROM_TRAINING_SESSION',
  AcceptedFromTrainingSessionEmailSent = 'ACCEPTED_FROM_TRAINING_SESSION_EMAIL_SENT',
  AccountCreated = 'ACCOUNT_CREATED',
  AccountImported = 'ACCOUNT_IMPORTED',
  EmailConfirmationSent = 'EMAIL_CONFIRMATION_SENT',
  EmailConfirmed = 'EMAIL_CONFIRMED',
  RecruitmentEmailScheduled = 'RECRUITMENT_EMAIL_SCHEDULED',
  RejectedDueToAbsenceEmailSent = 'REJECTED_DUE_TO_ABSENCE_EMAIL_SENT',
  RejectedEmailScheduled = 'REJECTED_EMAIL_SCHEDULED',
  RejectedEmailSent = 'REJECTED_EMAIL_SENT',
  RejectedFromRecruitmentSession = 'REJECTED_FROM_RECRUITMENT_SESSION',
  RejectedFromTrainingSession = 'REJECTED_FROM_TRAINING_SESSION'
}

export type TutorAvailability = {
  __typename?: 'TutorAvailability';
  hasTutorStopped: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isUnknown: Scalars['Boolean']['output'];
  nextAvailability?: Maybe<TutorAvailability>;
  nextAvailabilityId?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTimeISO']['output'];
  stopReason?: Maybe<Scalars['String']['output']>;
  tutorPropertiesId: Scalars['String']['output'];
  weekAvailability: Array<WeekdayAvailability>;
};

export type TutorAvailabilityUpdateInput = {
  hasTutorStopped?: InputMaybe<Scalars['Boolean']['input']>;
  isUnknown?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdatingAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  nextAvailability?: InputMaybe<TutorAvailabilityUpdateInput>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  stopReason?: InputMaybe<Scalars['String']['input']>;
  weekAvailability?: InputMaybe<Array<WeekdayAvailabilityUpdateInput>>;
};

export type TutorAvailableMissionWhereInput = {
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  levelTypes?: InputMaybe<Array<LevelType>>;
  type?: InputMaybe<MissionType>;
  weekdays?: InputMaybe<Array<Weekday>>;
};

export enum TutorBadge {
  Dys = 'DYS',
  Favorite = 'FAVORITE',
  Internship = 'INTERNSHIP',
  Literature = 'LITERATURE',
  Science = 'SCIENCE'
}

export type TutorCity = {
  __typename?: 'TutorCity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorDashboard = {
  __typename?: 'TutorDashboard';
  tutorMissions: Array<TutorMission>;
  tutorNextStudy?: Maybe<TutorStudy>;
  tutorUpcomingStudies: Array<TutorStudy>;
};

export enum TutorDiplomaHonour {
  AssezBien = 'ASSEZ_BIEN',
  Bien = 'BIEN',
  SansMention = 'SANS_MENTION',
  TresBien = 'TRES_BIEN'
}

export enum TutorDiplomaSpeciality {
  Es = 'ES',
  L = 'L',
  S = 'S'
}

export type TutorEstablishment = {
  __typename?: 'TutorEstablishment';
  address?: Maybe<TutorEstablishmentAddress>;
  availableMissions?: Maybe<Array<TutorMission>>;
  city: TutorEstablishmentCity;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notMatchingMissions?: Maybe<Array<TutorMission>>;
  tutorMatchingMissions?: Maybe<Scalars['Float']['output']>;
  tutorTotalMissions?: Maybe<Scalars['Float']['output']>;
};

export type TutorEstablishmentAddress = {
  __typename?: 'TutorEstablishmentAddress';
  id: Scalars['String']['output'];
  line: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type TutorEstablishmentCity = {
  __typename?: 'TutorEstablishmentCity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TutorInterviewMark {
  Expert = 'EXPERT',
  Good = 'GOOD',
  Ok = 'OK',
  Rejected = 'REJECTED'
}

export enum TutorMandatoryFields {
  Availability = 'AVAILABILITY',
  CriminalRecord = 'CRIMINAL_RECORD',
  FavoriteCities = 'FAVORITE_CITIES',
  Iban = 'IBAN',
  IdentityDocument = 'IDENTITY_DOCUMENT',
  IsContractSigned = 'IS_CONTRACT_SIGNED',
  Siret = 'SIRET'
}

export type TutorMission = {
  __typename?: 'TutorMission';
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTimeISO']['output'];
  establishment?: Maybe<TutorMissionEstablishment>;
  forcedLevels: Array<TutorMissionLevel>;
  id: Scalars['String']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isStandardRemote: Scalars['Boolean']['output'];
  levels: Array<TutorMissionLevel>;
  startDate: Scalars['DateTimeISO']['output'];
  studentsLevels: Array<TutorMissionLevel>;
  timeSlotWeeks: Array<TutorTimeSlotWeek>;
  tutorHourlyRate?: Maybe<Scalars['Float']['output']>;
  type: MissionType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TutorMissionEstablishment = {
  __typename?: 'TutorMissionEstablishment';
  city: TutorMissionEstablishmentCity;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorMissionEstablishmentCity = {
  __typename?: 'TutorMissionEstablishmentCity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorMissionLevel = {
  __typename?: 'TutorMissionLevel';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: LevelType;
};

export type TutorMissionResponse = {
  __typename?: 'TutorMissionResponse';
  id: Scalars['String']['output'];
  isAccepted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  mission: Mission;
  missionId: Scalars['String']['output'];
  tutorProperties: TutorProperties;
  tutorPropertiesId: Scalars['String']['output'];
};

export type TutorMissionTimeSlot = {
  __typename?: 'TutorMissionTimeSlot';
  endTime: Scalars['DateTimeISO']['output'];
  startTime: Scalars['DateTimeISO']['output'];
  weekday: Weekday;
};

export enum TutorOrderField {
  CreatedAt = 'CREATED_AT',
  FirstName = 'FIRST_NAME',
  LastLoginDate = 'LAST_LOGIN_DATE',
  LastName = 'LAST_NAME',
  TutorApplicantStatus = 'TUTOR_APPLICANT_STATUS',
  TutorRegistrationStep = 'TUTOR_REGISTRATION_STEP',
  TutorStatus = 'TUTOR_STATUS'
}

export type TutorOrderInput = {
  direction?: OrderDirection;
  field: TutorOrderField;
  nulls?: InputMaybe<Scalars['String']['input']>;
};

export type TutorPayrollInformation = {
  __typename?: 'TutorPayrollInformation';
  IBAN?: Maybe<Scalars['String']['output']>;
  SIRET?: Maybe<Scalars['String']['output']>;
  criminalRecordId?: Maybe<Scalars['String']['output']>;
  departments: Array<Department>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isContractSigned: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type TutorProperties = {
  __typename?: 'TutorProperties';
  IBAN?: Maybe<Scalars['String']['output']>;
  SIRET?: Maybe<Scalars['String']['output']>;
  absentRecruitmentMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  absentRejectionMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  absentTrainingMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  acceptedMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  applicantStatus: ApplicantStatus;
  availability?: Maybe<TutorAvailability>;
  availableAt?: Maybe<Scalars['DateTimeISO']['output']>;
  badges: Array<TutorBadge>;
  canSeePermanentMissions: Scalars['Boolean']['output'];
  canSeeReplacementMissions: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  contractUrl?: Maybe<Scalars['String']['output']>;
  criminalRecord?: Maybe<File>;
  criminalRecordId?: Maybe<Scalars['String']['output']>;
  customStudyEstablishment?: Maybe<Scalars['String']['output']>;
  customStudySubject?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<File>;
  cvId?: Maybe<Scalars['String']['output']>;
  departments?: Maybe<Array<Department>>;
  diplomaHonour?: Maybe<TutorDiplomaHonour>;
  diplomaNewSpecialities: Array<DiplomaNewSpeciality>;
  diplomaSpeciality?: Maybe<TutorDiplomaSpeciality>;
  diplomaTechnologicalSpeciality?: Maybe<TechnologicalSpeciality>;
  diplomaType?: Maybe<DiplomaType>;
  diplomaYear?: Maybe<Scalars['Float']['output']>;
  establishments?: Maybe<Array<Establishment>>;
  events: Array<TimelineEvent>;
  favoriteCities: Array<City>;
  firstDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  forcedEstablishments?: Maybe<Array<Establishment>>;
  hasCompletedRegistration: Scalars['Boolean']['output'];
  hasForeignDiploma: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  identityDocument?: Maybe<File>;
  identityDocumentId?: Maybe<Scalars['String']['output']>;
  incompleteRegistrationMailSentNumber: Scalars['Float']['output'];
  interviewMark?: Maybe<TutorInterviewMark>;
  isArchived: Scalars['Boolean']['output'];
  isBlacklisted: Scalars['Boolean']['output'];
  isContractSigned: Scalars['Boolean']['output'];
  isCustomStudyEstablishment: Scalars['Boolean']['output'];
  isCustomStudySubject: Scalars['Boolean']['output'];
  isForcedStandardRemote: Scalars['Boolean']['output'];
  isInStandardRemoteMission: Scalars['Boolean']['output'];
  isLastChance: Scalars['Boolean']['output'];
  isRejected: Scalars['Boolean']['output'];
  lastIncompleteRegistrationMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  missionEstablishments?: Maybe<Array<Establishment>>;
  missionLevel?: Maybe<TutorTestLevel>;
  motivation?: Maybe<Scalars['String']['output']>;
  otherFiles: Array<File>;
  otherSourcing?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<File>;
  profilePictureId?: Maybe<Scalars['String']['output']>;
  profiles: Array<Profile>;
  recruitedMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  recruitmentComment?: Maybe<Scalars['String']['output']>;
  recruitmentSessionInviteNumber: Scalars['Float']['output'];
  recruitmentSessionInviteSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referredBy?: Maybe<TutorProperties>;
  referredByCode?: Maybe<Scalars['String']['output']>;
  referredById?: Maybe<Scalars['String']['output']>;
  referredTutors: Array<TutorProperties>;
  registrationStep: TutorRegistrationStep;
  rejectedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  rejectionMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  secondDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  sessionRegistrations: Array<SessionRegistration>;
  sourcingChannel?: Maybe<SourcingChannel>;
  status: TutorStatus;
  studyEstablishment?: Maybe<Scalars['String']['output']>;
  studyLevel?: Maybe<TutorStudyLevel>;
  studyNumber: Scalars['Float']['output'];
  studySubject?: Maybe<Scalars['String']['output']>;
  testLevel?: Maybe<TutorTestLevel>;
  testMark?: Maybe<Scalars['Float']['output']>;
  thirdDiplomaNewSpeciality?: Maybe<DiplomaNewSpeciality>;
  trainedMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  trainingSessionInviteNumber: Scalars['Float']['output'];
  trainingSessionInviteSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  tutorMissionWeekdays: Array<Weekday>;
  user: User;
  userId: Scalars['String']['output'];
  willProvideSIRETLater: Scalars['Boolean']['output'];
};

export type TutorPropertiesUpdateInput = {
  IBAN?: InputMaybe<Scalars['String']['input']>;
  SIRET?: InputMaybe<Scalars['String']['input']>;
  applicantStatus?: InputMaybe<ApplicantStatus>;
  badges?: InputMaybe<Array<TutorBadge>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  diplomaHonour?: InputMaybe<TutorDiplomaHonour>;
  diplomaSpecialities?: InputMaybe<Array<TutorDiplomaSpeciality>>;
  diplomaType?: InputMaybe<DiplomaType>;
  diplomaYear?: InputMaybe<Scalars['Float']['input']>;
  favoriteCitiesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  forcedEstablishmentsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interviewMark?: InputMaybe<TutorInterviewMark>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  isForcedStandardRemote?: InputMaybe<Scalars['Boolean']['input']>;
  isPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  isReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  recruitmentComment?: InputMaybe<Scalars['String']['input']>;
  registrationStep?: InputMaybe<TutorRegistrationStep>;
  status?: InputMaybe<TutorStatus>;
  studyEstablishment?: InputMaybe<Scalars['String']['input']>;
  studyLevel?: InputMaybe<TutorStudyLevel>;
  studySubject?: InputMaybe<Scalars['String']['input']>;
  testLevel?: InputMaybe<TutorTestLevel>;
  testMark?: InputMaybe<Scalars['Float']['input']>;
};

export enum TutorRegistrationStep {
  Completed = 'COMPLETED',
  Motivation = 'MOTIVATION',
  SchoolInformation = 'SCHOOL_INFORMATION'
}

export type TutorSchoolClass = {
  __typename?: 'TutorSchoolClass';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum TutorStatus {
  Available = 'AVAILABLE',
  Inactive = 'INACTIVE',
  InMission = 'IN_MISSION',
  Unregistered = 'UNREGISTERED'
}

export type TutorStudent = {
  __typename?: 'TutorStudent';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type TutorStudentLevel = {
  __typename?: 'TutorStudentLevel';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: LevelType;
};

export type TutorStudentProperties = {
  __typename?: 'TutorStudentProperties';
  id: Scalars['String']['output'];
  level?: Maybe<TutorStudentLevel>;
  levelType?: Maybe<LevelType>;
  schoolClass?: Maybe<TutorSchoolClass>;
  user: TutorStudent;
};

export type TutorStudy = {
  __typename?: 'TutorStudy';
  address?: Maybe<TutorStudyAddress>;
  classRoom?: Maybe<TutorStudyClassRoom>;
  date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isHandledFromAdmin: Scalars['Boolean']['output'];
  isRemote: Scalars['Boolean']['output'];
  isTooEarly: Scalars['Boolean']['output'];
  isTooLate: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  mission: TutorStudyMission;
  status: StudyStatus;
  thematic?: Maybe<TutorStudyThematic>;
  timeSlot: TutorStudyTimeSlot;
};

export type TutorStudyAddress = {
  __typename?: 'TutorStudyAddress';
  city: TutorStudyCity;
  id: Scalars['String']['output'];
  line: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type TutorStudyCity = {
  __typename?: 'TutorStudyCity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorStudyClassRoom = {
  __typename?: 'TutorStudyClassRoom';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorStudyCoordinator = {
  __typename?: 'TutorStudyCoordinator';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type TutorStudyEstablishment = {
  __typename?: 'TutorStudyEstablishment';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TutorStudyLevel {
  BacPlusCinq = 'BAC_PLUS_CINQ',
  BacPlusDeux = 'BAC_PLUS_DEUX',
  BacPlusQuatre = 'BAC_PLUS_QUATRE',
  BacPlusSixEtPlus = 'BAC_PLUS_SIX_ET_PLUS',
  BacPlusTrois = 'BAC_PLUS_TROIS',
  BacPlusUn = 'BAC_PLUS_UN'
}

export type TutorStudyMission = {
  __typename?: 'TutorStudyMission';
  coordinator?: Maybe<TutorStudyCoordinator>;
  establishment?: Maybe<TutorStudyEstablishment>;
  id: Scalars['String']['output'];
  isStandardRemote: Scalars['Boolean']['output'];
};

export type TutorStudyRegistration = {
  __typename?: 'TutorStudyRegistration';
  feedback?: Maybe<Scalars['String']['output']>;
  feedbackSmsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  feedbackStatus?: Maybe<FeedbackStatus>;
  id: Scalars['String']['output'];
  isStudentPresent?: Maybe<Scalars['Boolean']['output']>;
  studentProperties: TutorStudentProperties;
  twilioSmsStatus?: Maybe<Scalars['String']['output']>;
};

export type TutorStudyThematic = {
  __typename?: 'TutorStudyThematic';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TutorStudyTimeSlot = {
  __typename?: 'TutorStudyTimeSlot';
  endTime: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  startTime: Scalars['DateTimeISO']['output'];
};

export enum TutorTestLevel {
  Premiere = 'PREMIERE',
  Primaire = 'PRIMAIRE',
  QuatriemeTroisieme = 'QUATRIEME_TROISIEME',
  Seconde = 'SECONDE',
  SixiemeCinquieme = 'SIXIEME_CINQUIEME',
  Terminale = 'TERMINALE'
}

export type TutorTimeSlotWeek = {
  __typename?: 'TutorTimeSlotWeek';
  recurrenceRule?: Maybe<StudyRecurrenceRule>;
  timeSlots: Array<TutorMissionTimeSlot>;
};

export type TutorUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<TutorPropertiesUpdateInput>;
  title?: InputMaybe<UserTitle>;
};

export type TutorWhereInput = {
  applicantStatuses?: InputMaybe<Array<ApplicantStatus>>;
  availabilityWeekdays?: InputMaybe<Array<Weekday>>;
  badges?: InputMaybe<Array<TutorBadge>>;
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  establishmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  favoriteCities?: InputMaybe<Array<Scalars['String']['input']>>;
  interviewMark?: InputMaybe<TutorInterviewMark>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  isInStandardRemoteMission?: InputMaybe<Scalars['Boolean']['input']>;
  isRejected?: InputMaybe<Scalars['Boolean']['input']>;
  isReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  profiles?: InputMaybe<Array<Profile>>;
  regionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<TutorStatus>>;
  testLevel?: InputMaybe<TutorTestLevel>;
};

export type Utm = {
  __typename?: 'UTM';
  campaign?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type UtmInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Array<Comment>>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  emailConfirmedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  fullScope: FullScope;
  hasAcceptedSalesTerms?: Maybe<Scalars['Boolean']['output']>;
  hasAcceptedUseTerms?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  importRefId?: Maybe<Scalars['String']['output']>;
  isEmailConfirmed: Scalars['Boolean']['output'];
  isImported: Scalars['Boolean']['output'];
  isPhoneValid: Scalars['Boolean']['output'];
  lastLoginDate?: Maybe<Scalars['DateTimeISO']['output']>;
  lastName: Scalars['String']['output'];
  negativeCommentNumber?: Maybe<Scalars['Float']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  properties: UserProperties;
  role: UserRole;
  scope: Scope;
  title?: Maybe<UserTitle>;
  updatedAt: Scalars['DateTimeISO']['output'];
  utm: Utm;
  welcomeMailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type UserProperties = ParentProperties | StaffProperties | StudentProperties | TutorProperties;

export enum UserRole {
  Admin = 'ADMIN',
  Applicant = 'APPLICANT',
  Coordinator = 'COORDINATOR',
  Guest = 'GUEST',
  Manager = 'MANAGER',
  Parent = 'PARENT',
  Student = 'STUDENT',
  SuperAdmin = 'SUPER_ADMIN',
  Tutor = 'TUTOR'
}

export enum UserTitle {
  Mister = 'MISTER',
  Mistress = 'MISTRESS'
}

export type UserUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<UserTitle>;
};

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type WeekdayAvailability = {
  __typename?: 'WeekdayAvailability';
  id: Scalars['String']['output'];
  isUnavailable: Scalars['Boolean']['output'];
  startTime?: Maybe<Scalars['DateTimeISO']['output']>;
  tutorAvailability: TutorAvailability;
  tutorAvailabilityId: Scalars['String']['output'];
  weekday: Weekday;
};

export type WeekdayAvailabilityUpdateInput = {
  isUnavailable: Scalars['Boolean']['input'];
  startTime?: InputMaybe<Scalars['DateTimeISO']['input']>;
  weekday: Weekday;
};

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'CurrentUser', id: string, email: string, role: UserRole, firstName: string, lastName: string, fullName: string } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'CurrentUser', id: string, email: string, role: UserRole, firstName: string, lastName: string, fullName: string } | null };

export type CurrentUserFragment = { __typename?: 'CurrentUser', id: string, email: string, role: UserRole, firstName: string, lastName: string, fullName: string };

export type GeneratePresignedUrlMutationVariables = Exact<{
  fileId: Scalars['String']['input'];
}>;


export type GeneratePresignedUrlMutation = { __typename?: 'Mutation', generatePresignedUrl: string };

export type IsParentRegistrationEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type IsParentRegistrationEnabledQuery = { __typename?: 'Query', isParentRegistrationEnabled: boolean };

export type IsTutorRegistrationEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type IsTutorRegistrationEnabledQuery = { __typename?: 'Query', isTutorRegistrationEnabled: boolean };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'CurrentUser', id: string, email: string, role: UserRole, firstName: string, lastName: string, fullName: string } } };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'LoginResponse', accessToken: string, user: { __typename?: 'CurrentUser', id: string, email: string, role: UserRole, firstName: string, lastName: string, fullName: string } } };

export type SendResetPasswordMailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendResetPasswordMailMutation = { __typename?: 'Mutation', sendResetPasswordMail: boolean };

export const CurrentUserFragmentDoc = gql`
    fragment currentUser on CurrentUser {
  id
  email
  role
  firstName
  lastName
  fullName
}
    `;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token) {
    accessToken
    user {
      ...currentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useConfirmEmailMutation({
 *   variables: {
 *     token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(options: VueApolloComposable.UseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...currentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a Vue component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCurrentUserQuery();
 */
export function useCurrentUserQuery(options: VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, {}, options);
}
export function useCurrentUserLazyQuery(options: VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CurrentUserQuery, CurrentUserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, {}, options);
}
export type CurrentUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CurrentUserQuery, CurrentUserQueryVariables>;
export const GeneratePresignedUrlDocument = gql`
    mutation GeneratePresignedUrl($fileId: String!) {
  generatePresignedUrl(fileId: $fileId)
}
    `;

/**
 * __useGeneratePresignedUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePresignedUrlMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePresignedUrlMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useGeneratePresignedUrlMutation({
 *   variables: {
 *     fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGeneratePresignedUrlMutation(options: VueApolloComposable.UseMutationOptions<GeneratePresignedUrlMutation, GeneratePresignedUrlMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<GeneratePresignedUrlMutation, GeneratePresignedUrlMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<GeneratePresignedUrlMutation, GeneratePresignedUrlMutationVariables>(GeneratePresignedUrlDocument, options);
}
export type GeneratePresignedUrlMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<GeneratePresignedUrlMutation, GeneratePresignedUrlMutationVariables>;
export const IsParentRegistrationEnabledDocument = gql`
    query IsParentRegistrationEnabled {
  isParentRegistrationEnabled
}
    `;

/**
 * __useIsParentRegistrationEnabledQuery__
 *
 * To run a query within a Vue component, call `useIsParentRegistrationEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsParentRegistrationEnabledQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIsParentRegistrationEnabledQuery();
 */
export function useIsParentRegistrationEnabledQuery(options: VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>(IsParentRegistrationEnabledDocument, {}, options);
}
export function useIsParentRegistrationEnabledLazyQuery(options: VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>(IsParentRegistrationEnabledDocument, {}, options);
}
export type IsParentRegistrationEnabledQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IsParentRegistrationEnabledQuery, IsParentRegistrationEnabledQueryVariables>;
export const IsTutorRegistrationEnabledDocument = gql`
    query IsTutorRegistrationEnabled {
  isTutorRegistrationEnabled
}
    `;

/**
 * __useIsTutorRegistrationEnabledQuery__
 *
 * To run a query within a Vue component, call `useIsTutorRegistrationEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTutorRegistrationEnabledQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIsTutorRegistrationEnabledQuery();
 */
export function useIsTutorRegistrationEnabledQuery(options: VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>(IsTutorRegistrationEnabledDocument, {}, options);
}
export function useIsTutorRegistrationEnabledLazyQuery(options: VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>(IsTutorRegistrationEnabledDocument, {}, options);
}
export type IsTutorRegistrationEnabledQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IsTutorRegistrationEnabledQuery, IsTutorRegistrationEnabledQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    user {
      ...currentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     email: // value for 'email'
 *     password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(options: VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    accessToken
    user {
      ...currentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetPasswordMutation({
 *   variables: {
 *     token: // value for 'token'
 *     password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(options: VueApolloComposable.UseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetPasswordMailDocument = gql`
    mutation SendResetPasswordMail($email: String!) {
  sendResetPasswordMail(email: $email)
}
    `;

/**
 * __useSendResetPasswordMailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendResetPasswordMailMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMailMutation(options: VueApolloComposable.UseMutationOptions<SendResetPasswordMailMutation, SendResetPasswordMailMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendResetPasswordMailMutation, SendResetPasswordMailMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SendResetPasswordMailMutation, SendResetPasswordMailMutationVariables>(SendResetPasswordMailDocument, options);
}
export type SendResetPasswordMailMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SendResetPasswordMailMutation, SendResetPasswordMailMutationVariables>;